import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { GreenButton } from '../GetrivestButtons';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import settings from '../../services/settingsService';

const EditProfile = (props) => {
    const user_id = props.user.id;
    const token = props.user.token;
    const photo = props.user.profile_photo;
    const url = 'http://api.test.getripay.com';  //test
    //const url = 'https://api.getripay.com';  //live
    const completeUrl = url + photo;

    const [update, setUpdate] = useState({
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        others_name: props.user.others_name ? props.user.others_name : '',
        email: props.user.email,
        phone_number: props.user.phone_number,
        gender: props.user.gender ? props.user.gender : '',
        marital_status: props.user.marital_status ? props.user.marital_status : '',
        address: props.user.address ? props.user.address : '',
        date_of_birth: props.user.date_of_birth ? props.user.date_of_birth : '',
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setUpdate((update) => ({ ...update, [name]: value }));
    }

    const handleChangePicture = ({ meta, file }, status) => { console.log(status, meta, file) }

    const handleSubmitPicture = (files, allFiles) => {
        console.log(files[0].file)
        props.update_profile_picture(files[0].file, token, user_id)
    }

    function handleSubmitProfile(e) {
        e.preventDefault();
        if (update.first_name && update.last_name && update.others_name && update.gender && update.marital_status && update.address) {
            props.update_profile(update, token, user_id);
        }
    }


    return (
        <div className='ml-lg-4'>
            <h4 className='font-weight-bolder'>Edit Profile</h4>
            <p>Personal Information</p>
            <div className='edit-profile mt-4 p-3'>
                {/* <img src='/images/avatar.jpg' className='settings-img' alt='profile' /> */}
                <Dropzone
                    maxFiles={1}
                    multiple={false}
                    onChangeStatus={handleChangePicture}
                    onSubmit={handleSubmitPicture}
                    inputContent={<img src={completeUrl} className='settings-img' alt='profile' />}
                    accept="image/*"
                />
                {/* <img src='/images/upload_icon.png' className='img-fluid setting-avatar' width='45' alt='avatar' /> */}

                <Form onSubmit={handleSubmitProfile}>
                    <Form.Group>
                        <Form.Control onChange={handleChange} disabled type="text" placeholder="Full Name" className='mb-4 mt-4' value={update.first_name} />
                        <Form.Control onChange={handleChange} disabled type="text" placeholder="Last Name" className='mb-4' value={update.last_name} />
                        <Form.Control onChange={handleChange} type="text" placeholder="Middle Name" className='mb-4' value={update.others_name} />
                        <Form.Control onChange={handleChange} disabled type="email" placeholder="Email address" className='mb-4' value={update.email} />
                        <Form.Control onChange={handleChange} as='select' placeholder="Gender" className='mb-4' value={update.gender}>
                            <option value='male'>Male</option>
                            <option value='female'>Female</option>
                        </Form.Control>
                        <Form.Control onChange={handleChange} type="number" placeholder="Phone Number" className='mb-4' value={update.phone_number} />
                        <Form.Control onChange={handleChange} as='select' placeholder="Marital Status" className='mb-4' value={update.marital_status}>
                            <option value='single'>Single</option>
                            <option value='married'>Married</option>
                            <option value='divorced'>Divorced</option>
                            <option value='separated'>Separated</option>
                            <option value='registered'>Registered</option>
                            <option value='partnership'>Partnership</option>
                        </Form.Control>
                        <Form.Control onChange={handleChange} type="text" placeholder="Address" className='mb-4' value={update.address} />
                        {props.user.date_of_birth ?
                            <p >Date of birth: {update.date_of_birth}</p> :
                            <Form.Control onChange={handleChange} type="date" placeholder="Date of Birth" className='mb-4' value={update.date_of_birth} />
                        }

                    </Form.Group>
                    <div className='text-center'>
                        <GreenButton text='Save Changes' />
                    </div>
                </Form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        update_profile: (update, token, user_id) => dispatch(settings.update_profile(update, token, user_id)),
        update_profile_picture: (file, token, user_id) => dispatch(settings.update_profile_picture(file, token, user_id)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)