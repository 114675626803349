import React, {useState} from 'react'
import { connect } from 'react-redux'
import CartBody  from '../../components/Dashboard/CartBody'
import { DashboardBanner } from '../../components/Dashboard/DashboardBanner'
import Navigation from '../../components/Navigation'

export const Cart = (props) => {
    return (
        <div >
            <Navigation/>
            <DashboardBanner text='Subscription Summary' image='/images/header_bg.png' />
            <CartBody {...props} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    };
};

export default connect(mapStateToProps, null)(Cart)