import React, { useEffect, useState } from 'react';
import { Col, Container, DropdownButton, Row, Dropdown, Form, Modal, Button } from 'react-bootstrap';
import CardComponent from '../CardComponent';
import { connect } from 'react-redux';
import investment from '../../services/investmentService';
import { Loader } from '../Loader';

const PackagesSectionTwo = (props) => {
    const [sortHighest, setSortHighest] = useState(null);
    const [sortLowest, setSortLowest] = useState(null);
    const [search, setSearch] = useState([]);
    const [sort, setSort] = useState(false);
    const [filtering, setFiltering] = useState(false);
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(1)

    function handleOpen() {
        setOpen(true);
    }
    function handleClose() {
        setOpen(false)
    }

    useEffect(() => {
        props.get_all_product();
    }, [filtering]);

    function refresh() {
        window.location.reload(true);
    }

    // Sort by highest amount
    const sortByHighestAmount = () => {
        setSort(true)
        setSortHighest(props.data.sort((a, b) => b.investment_cycle.cost_per_slot - a.investment_cycle.cost_per_slot));
    };

    // Sort by lowest amount
    const sortByLowestAmount = () => {
        setSort(true)
        setSortLowest(props.data.sort((a, b) => a.investment_cycle.cost_per_slot - b.investment_cycle.cost_per_slot));
    };

    const searchByName = (e) => {
        var search_field = props.data.filter(element => element.name.includes(e.target.value));
        setSearch(search_field)
        setFiltering(true)
    }


    const searchByCategory = (data) => {
        var search_field = props.data.filter(element => element.category.industry.includes(data));
        setSearch(search_field)
        setFiltering(true)
    }

    const searchByInterest = (data) => {
        const begin = data * current - 10;
        const end = begin + 10;
        var search_field = props.data.filter(element => {
            if (element.investment_cycle.ROI_percentage > begin && element.investment_cycle.ROI_percentage <= end) {
                return element.investment_cycle.ROI_percentage
            }
        });
        setSearch(search_field)
        setFiltering(true)
    }

    const searchByAvailable = (data) => {
        var search_field = props.data.filter(element => {
            if (element.investment_cycle.slot - element.investment_cycle.slots_taken > 0) {
                return element.investment_cycle.ROI_percentage
            }
        });
        setSearch(search_field)
        setFiltering(true)
    }

    const searchBySold = (data) => {
        var search_field = props.data.filter(element => {
            if (element.investment_cycle.slot - element.investment_cycle.slots_taken === 0) {
                return element.investment_cycle.ROI_percentage
            }
        });
        setSearch(search_field)
        setFiltering(true)
    }

    const showItem = () => {
        let itemToShow;
        if (filtering) {
            itemToShow = search
        } else if (sort) {
            itemToShow = props.data
        } else {
            itemToShow = props.data
        }
        return itemToShow
    }


    return (
        <div className='section-two mb-5'>
            {props.loading &&
                <Loader />
            }
            {props.data &&
                <Container className='mt-5'>
                    <Row>
                        <Col lg='5'></Col>
                        <Col lg='2' sm='6' xs='6' className=' mt-lg-5'>
                            <button onClick={handleOpen} variant='white' className='text-primary filter-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel-fill" viewBox="0 0 16 16">
                                    <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2z" />
                                </svg>
                                {' '}Filter
               </button>
                        </Col>
                        <Col lg='2' sm='6' xs='6' className=' mt-lg-5'>
                            <button onClick={refresh} variant='white' className='text-primary filter-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                </svg>
                                {' '}Refresh
               </button>
                        </Col>
                        <Col lg='3'></Col>
                    </Row>
                </Container>
            }


            <Modal show={open} onHide={handleClose} centered size='lg' >
                <Modal.Header closeButton className='text-center'>FILTERS</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg='6' className='text-center '>
                            <DropdownButton id="dropdown-basic-button" title="Sort by amount" variant='primary' >
                                <Dropdown.Item onClick={sortByHighestAmount}>Highest amount</Dropdown.Item>
                                <Dropdown.Item onClick={sortByLowestAmount}>Lowest amount</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col lg='6' className='text-center'>
                            <DropdownButton id="dropdown-basic-button" title="Sort by category" variant='primary' >
                                <Dropdown.Item onClick={() => searchByCategory('Real Estate')}>Real estate</Dropdown.Item>
                                <Dropdown.Item onClick={() => searchByCategory('Agric')}>Agriculture</Dropdown.Item>
                                <Dropdown.Item onClick={() => searchByCategory('technology')}>Technology</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                        <Col lg='6' className='mt-3 text-center'>
                            <DropdownButton id="dropdown-basic-button" title="Sort by interest rate" variant='primary' >
                                <Dropdown.Item onClick={() => searchByInterest(10)}>1 - 10%</Dropdown.Item>
                                <Dropdown.Item onClick={() => searchByInterest(20)}>11 - 20%</Dropdown.Item>
                                <Dropdown.Item onClick={() => searchByInterest(30)}>21 - 30%</Dropdown.Item>
                                <Dropdown.Item onClick={() => searchByInterest(40)}>31 - 40%</Dropdown.Item>
                            </DropdownButton>
                        </Col>

                        <Col lg='6' className='mt-3 text-center'>
                            <DropdownButton id="dropdown-basic-button" title="Sort by available slots" variant='primary' >
                                <Dropdown.Item onClick={searchByAvailable}>Available Slots</Dropdown.Item>
                                <Dropdown.Item onClick={searchBySold}>Sold out Slots</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                    </Row>

                    <Row className='mb-4 mt-3'>
                        <Col lg='12' className='mb-4'>
                            <p className='text-center m-0 mt-3'> Search by name</p>
                            <input className='second-input p-2' type='search' name='searchName' onChange={searchByName} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Container className='mt-3'>
                {props.data ?
                    <Row>
                        {showItem().map((newData, i) => (
                            <Col md={6} lg={4} sm={12} xs={12} key={i}>
                                <CardComponent {...newData} />
                            </Col>
                        ))}

                    </Row>
                    :
                    <Row className='mt-5 text-center pt-5'>
                        <Col>
                            <h4 className='font-weight-bolder mt-5 pt-5'> No investment product to show</h4>
                        </Col>
                    </Row>

                }

            </Container>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        loading: state.listAllProducts.isLoading,
        data: state.listAllProducts.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_all_product: () => dispatch(investment.get_all_product()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackagesSectionTwo);
