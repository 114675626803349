import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const AboutSectionTwo = () => {
    return (
        <Container fluid className='section-two ' id='learn'>
            <Row>
                <Col lg={1}></Col>
                <Col lg={5}>
                    <h1 className='section-two-text mb-4'>All About GetriVest</h1>
                    <p>
                        GetriVest is an agro-allied investment initiative aiming to change the
                        narrative when it comes to agriculture and allied industry investments.
                        We believe it is not enough to merely think of agriculture in terms of farming
                        but also to see the endless possibilities of agricultural production and allied
                        businesses and their impact on the future of agriculture in Nigeria and Africa as a
                        whole. We are all about building a complete value chain of farm production that covers
                        the every stage of farming, harvesting, and processing.
                        <br />
                        <br />
                        Our technology-driven platform was created with the mind to
                         provide an investment opportunity to the individual who has
                         never even dreamt of owning a farm or even plucked
                        a chicken in his/her life but understands they can make gains everyday
                        literally like chickens lay eggs.
                        <br /><br />
                        To ensure that your investments continues yielding for a long time to
                        come, our team comprises of skilled engineers and experienced financial
                        managers coupled with 8 years in wealth of experience garnered from first-hand
                        farm administration and production processes. We are always working to give
                        you a seamless platform that is easy to navigate and an open channel of
                        communication between you and us.
                        <br /><br />
                        Some of our sister-companies work along-side GetriVest in handling various aspects
                        of your investments so you can be assured we are constantly monitoring how  your
                        investments grows from a seed into an harvest that will make you smile. They include: <br/>
                        PatrickGold Micro-Finance Bank<br/>
                        Duro Farm and Products Ltd.<br/>
                        Duro Agro and Allied Farm.<br/>
                        GetriPay.
                        <br/><br/>

                        A team like ours not only knows the best farm-crops to invest your money in but also the right agro-allied 
                        businesses with high returns on investment and our financial managers serving as watchmen over every single detail involved.

                    </p>
                </Col>
                <Col lg={1}></Col>
                <Col lg={5}>
                    <img src='/images/crops_about.png' alt='about' className='about-banner-img img-fluid ' />
                </Col>

            </Row>
        </Container>
    )
}
