import React from 'react'
import { PackagesBanner } from '../components/Packages/PackagesBanner'
import Navigation from '../components/Navigation'
import PackagesSectionTwo from '../components/Packages/PackagesSectionTwo'
import { Footer } from '../components/Footer'

export const Packages = () => {
    return (
        <div>
            <Navigation/>
            <PackagesBanner/>
            <PackagesSectionTwo/>
            <Footer/>
        </div>
    )
}
