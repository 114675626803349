import React from 'react'
import {Row, Col } from 'react-bootstrap'
import { history } from '../../store/history'
import { GreenButton } from '../GetrivestButtons'
import { numberFormatter } from '../numberFormatter'

export const ReturnsBox = (props) => {
    return (
        <div className='box p-3'>
            <p className='getrivest-orange mb-3'>Investments & Returns</p>

                <Row>
                    <Col lg={6} sm={6} xs={6} >
                       <p className='text-left box-roi card-small p-1 m-0'>ROI</p>
                       <h5 className='box-roi-value'>NGN {numberFormatter(props.overall.all_investment_accrued_interest) } </h5>
                   </Col>
                    <Col lg={6} sm={6} xs={6} >
                      <p className='ml-5 text-center box-left card-small p-1 m-0'>TOTAL SLOTS</p>
                      <h6 className='ml-5 text-center font-weight-bolder'>{props.overall.all_investment_slots}</h6>
                   </Col>
                </Row>
                <hr/>
                <Row>
                    <Col lg={6} sm={6} xs={6} >
                       <p className='text-left box-invested card-small p-1 m-0'>AMOUNT INVESTED</p>
                       <p className='inv'>NGN {numberFormatter(props.overall.all_investment_amount) }</p>
                   </Col>
                    <Col lg={6} sm={6} xs={6} >
                      <p className='ml-5 text-center box-invested card-small p-1 m-0'>DATE</p>
                      <p className='ml-5 text-center getrivest-green for-small-box'>27 JAN, 2021</p>
                   </Col>
                </Row>
                <GreenButton text='New Investment' click={()=>history.push('/packages')}/>
        </div>
    )
}
