import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import listAllProducts from './listAllProductsReducer';
import login from './loginReducer';
import verifyLogin from './verifyLoginReducer';
import viewProductDetail from './viewProductDetailReducer';
import subscribe from './subscriptionReducer';
import listSubscription from './listSubscriptionReducer';
import investmentHistory from './investmentHistoryReducer';
import register from './registrationReducer';
import verifyPhone from './verifyPhoneReducer';
import allSecurityQuestions from './allSecuirtyQuestionsReducer';
import submitQuestion from './submitQuestionReducer';
import updateProfile from './updateProfileReducer';
import updateProfilePicture from './updateProfilePictureReducer';
import changePassword from './changePasswordReducer';
import accountBalance from './accountBalanceReducer';
import setPin from './setPinReducer';
import submitBvn from './submitBvnReducer';
import verifyBvn from './verifyBvnReducer';
import uploadDocument from './uploadDocumentReducer';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userDetails"],
};

const rootReducer = combineReducers({
  listAllProducts,
  login,
  userDetails: verifyLogin,
  viewProductDetail,
  subscribe,
  listSubscription,
  investmentHistory,
  register,
  verifyPhone,
  allSecurityQuestions,
  submitQuestion,
  updateProfile,
  updateProfilePicture,
  changePassword,
  setPin,
  accountBalance,
  submitBvn,
  verifyBvn,
  uploadDocument
});

export default persistReducer(persistConfig, rootReducer);
