import React, { useState, useEffect } from 'react'
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { toast, Flip } from 'react-toastify';
import { history } from '../../store/history';


export const PackageModal = (props) => {
    const [disable, setDisable] = useState(false);

    const invest = {
        investment_cycle_id: props.id,
        investment_product_id: props.investment_cycle.investment_product_id,
        slots: props.investment_cycle.minimum_slot,
        recurrent_subscription: false,
        name: props.name,
        roi: props.investment_cycle.ROI_percentage,
        cost_per_slot: props.investment_cycle.cost_per_slot,
        min_slots: props.investment_cycle.minimum_slot,
        slot_left: props.investment_cycle.slot - props.investment_cycle.slots_taken,
        period: props.period,
        start_date: new Date(props.investment_cycle.start_date).toDateString(),
        end_date: new Date(props.investment_cycle.end_date).toDateString()
    }

    function addtoCart() {

        let products = [];

        if (localStorage.getItem('products')) {
            products = JSON.parse(localStorage.getItem('products'));
        }

        //To prevent duplicate items in cart
        let existingItem = products.find(cartItem => cartItem.investment_cycle_id === props.id);

        if (existingItem) {
            setDisable(true)
            products = JSON.parse(localStorage.getItem('products'));
        } else {
            products.push(invest);
            localStorage.setItem('products', JSON.stringify(products));
            toast.success("Successfully added to cart", {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
            })
        }

    };

    function gotoCart() {
        history.push('/cart')
    }



    useEffect(() => {
        let products = [];

        if (localStorage.getItem('products')) {
            products = JSON.parse(localStorage.getItem('products'));
        }
        //To disable button
        let existingItem = products.find(cartItem => cartItem.investment_cycle_id === props.id);

        if (existingItem) {
            setDisable(true)
        }
    }, [])

    return (
        <>
            <Modal show={props.show} onHide={props.close} size='lg'>
                <Modal.Header closeButton className='pl-5 pr-5'>
                    <img src={props.image_url} alt='investment' height='300' style={{ marginTop: '-16px', width: '100%' }} />
                </Modal.Header>
                <Modal.Body className='ml-3'>
                    <Container>
                        <Row>
                            <Col lg={5} md={6} sm={12} xs={12}>
                                <span className='package-modal-title'>{props.name}</span>
                                <div>
                                    <span className='card-small'> ROI - </span>
                                    <span className='package-modal-roi'>{props.investment_cycle.ROI_percentage}%</span>
                                </div>
                                <span className='package-modal-unit'>NGN {Number(props.investment_cycle.cost_per_slot).toLocaleString()}/Unit</span>
                                <span className='package-modal-date'>{new Date(props.investment_cycle.start_date).toDateString()} - {new Date(props.investment_cycle.end_date).toDateString()}</span>
                                <span className='package-modal-period'> ({props.period} days)</span>
                                <div className='font-weight-bolder'>
                                    Insured by:{' '}
                                    <img src='/images/leadway.png' className='img-fluid' width='180' />
                                </div>

                            </Col>
                            <Col lg={7} md={6} sm={12} xs={12}>
                              
                                <div>
                                    <h5 className='font-weight-bolder'>MORE DETAILS</h5>
                                    {props.current_progress !== null &&
                                        <p>Progress: {props.current_progress.description} stage</p>
                                    }

                                    <p className='package-modal-info'>
                                  -  {props.description}
                                    </p>
                                   
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>

                <div>


                    <Row >
                        <Col lg='6' className='mt-4'>
                            <button
                                onClick={props.close}
                                style={{

                                    backgroundColor: '#33cc33',
                                    padding: '10px',
                                    border: '2px solid #33cc33',
                                    color: 'white',
                                    width: '100%'
                                }}
                            >CONTINUE SHOPPING</button>
                        </Col>
                        {/* <Col lg='1'></Col> */}
                        {disable ?
                            <Col lg='6' className='mt-4' >
                                <button
                                    onClick={gotoCart}
                                    style={{
                                        backgroundColor: '#999999',
                                        padding: '10px',
                                        border: '2px solid #999999',
                                        color: 'white',
                                        width: '100%'
                                    }}
                                >CHECKOUT</button>
                            </Col>
                            :
                            <Col lg='6' className='mt-4'>
                                <button
                                    onClick={addtoCart}
                                    style={{
                                        backgroundColor: '#ff9933',
                                        padding: '10px',
                                        border: '2px solid #ff9933',
                                        color: 'white',
                                        width: '100%'
                                    }}
                                >ADD TO CART</button>
                            </Col>
                        }

                    </Row>

                </div>

            </Modal>
        </>
    )
}
