import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { GreenButton } from '../GetrivestButtons';
import { connect } from 'react-redux';
import account from '../../services/accountService';

const LinkBvn = (props) => {
    const token = props.user ? props.user.token : '';



    const [validate, setValidate] = useState({
        bvn_number: '',
        user_id: props.user ? props.user.id : ''
    });

    const [verify, setVerify] = useState({
        user_id: props.user ? props.user.id : '',
        bvn_token: props.data.data ? props.data.data.bvn_token : '',
        dob: ''
    });

    useEffect(() => {
        setVerify({
            user_id: props.user ? props.user.id : '',
            bvn_token: props.data.data ? props.data.data.bvn_token : '',
            dob: ''
        })
    }, [props.data])


    function handleChange(e) {
        const { name, value } = e.target;
        setValidate((validate) => ({ ...validate, [name]: value }));
    }

    function handleChangeVerify(e) {
        const { name, value } = e.target;
        setVerify((verify) => ({ ...verify, [name]: value }));
    };

    function handleSubmit(e) {
        e.preventDefault();
        if (validate.bvn_number) {
            props.submit_bvn(validate, token)
        }
    };

    function handleVerify(e) {
        e.preventDefault();
        if (validate.bvn_number) {
            props.verify_bvn(verify, token)
        }
    };

    return (
        <div className='ml-lg-4'>
            <h4 className='font-weight-bolder'>Link BVN</h4>
            <p>Enter your 11-digit Bank Verification Number</p>
            <div className='edit-profile mt-4 p-3'>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control onChange={handleChange} name='bvn_number' value={validate.bvn_number} type="number" placeholder="BVN" className='mb-4 mt-4' />
                    </Form.Group>
                    <div className='text-center'>
                        <GreenButton text='Submit' loading={props.loading} disable={props.data.response_code === 'USM023' || props.user.bvn_verify === true} />
                    </div>
                </Form>
            </div>
            {props.user.bvn_verify === true ?
                <p className='text-danger font-weight-bolder text-center mt-2'>
                    Your BVN has alreaady been verified - {props.user.bvn}
                </p>
                :
                ''

            }

            {props.data.response_code === 'USM023' ?
                <>
                    <p className='mt-3'>Enter your date of birth to complete your bvn verification</p>
                    <div className='edit-profile mt-4 p-3'>
                        <Form onSubmit={handleVerify}>
                            <Form.Group>
                                <Form.Control onChange={handleChangeVerify} name='dob' value={verify.dob} type="date" placeholder="Date of Birth" className='mb-4 mt-4' />
                            </Form.Group>
                            <div className='text-center'>
                                <GreenButton text='Submit' loading={props.loading2} />
                            </div>
                        </Form>
                    </div>
                </>


                :
                ''

            }
        </div >
    )
};


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.submitBvn.isLoading,
        data: state.submitBvn.data,
        loading2: state.verifyBvn.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submit_bvn: (validate, token) => dispatch(account.submit_bvn(validate, token)),
        verify_bvn: (verify, token) => dispatch(account.verify_bvn(verify, token))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkBvn);