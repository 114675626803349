import React from 'react'

export const StartNewBox = (props) => {
    return (
        <div className='box p-3'>
            <div className='mt-5 text-center'>
                <img src='/images/add icon.png' alt='add' className='new-add mt-4'/>
                <p className='text-center getrivest-orange mt-2'>{props.text}</p>
            </div>
        </div>
    )
}
