import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { AccountActionsCarouselModal } from '../../components/Dashboard/Auth/AccountActionsCarouselModal'
import { DashboardBanner } from '../../components/Dashboard/DashboardBanner'
import { ProfileCard } from '../../components/Dashboard/ProfileCard'
import { TabComponent } from '../../components/Dashboard/TabComponent'
import Navigation from '../../components/Navigation'
import account from '../../services/accountService'
import investment from '../../services/investmentService'

const Dashboard = (props) => {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const id = props.user.id;
    const account_no = props.user.bank_account ? props.user.bank_account[0].account_number : ''
    useEffect(() => {
        props.list_subscription(id);
        props.get_account_balance(account_no)
        props.get_all_investment(id);
    }, [])

    return (
        <div>
            <Navigation />
            <DashboardBanner {...props} text='Dashboard' image='/images/header_dashboard.png' />
            <Container className='mt-4 mb-4'>
                <Row>
                    <Col md={5} lg={3} sm={12} xs={12} className='mt-4'>
                        <ProfileCard {...props} />
                    </Col>
                    <Col md={7} lg={9} sm={12} xs={12} className='mt-4'>
                        <TabComponent {...props} />
                    </Col>
                </Row>
                {/* <AccountActionsCarouselModal show={show} close={handleClose} /> */}
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        sub: state.listSubscription.data,
        balance: state.accountBalance.data,
        overall: state.listAllProducts.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        list_subscription: (id) => dispatch(investment.list_subscription(id)),
        get_account_balance: (account_no) => dispatch(account.get_account_balance(account_no)),
        get_all_investment:(id) => dispatch(investment.get_all_investment(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);