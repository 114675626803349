import React, { useState } from 'react';
import { Container, Modal, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import auth from '../../../services/authService';
import { GreenDashboardButton } from '../../GetrivestButtons';
import { Loader } from '../../Loader';
import SetSecurityQuestionModal from './SetSecurityQuestionModal';

const VerificationModal = (props) => {
    const [showSecurity, setShowSecurity] = useState(false);

    function handleClose() {
        setShowSecurity(false)
    };

    const [user, setUser] = useState({
        token: '',
        register_token: sessionStorage.getItem('register_token'),
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    };

    function submit() {
        if (user.token && user.register_token) {
            props.verify_user_phone(user).then(() => {
               
                    setShowSecurity(true)
                
            })
        }
    };


    return (
        <>
            <Modal show={props.show === true && props.data.success !== true} centered size='lg'>
                {props.loading ?
                    <Loader />
                    :
                    ''
                }
                <Modal.Body className='p-0'>
                    <Container fluid className='m-0 p-0'>
                        <Row>
                            <Col lg='6' md='12' sm='12' xs='12'>
                                <img src='/images/pattern_bg.png' alt='pattern' className='img-fluid reg-image' />
                            </Col>

                            <Col lg='6' md='12' sm='12' xs='12' className='pl-4 pr-5 pt-3 pb-5 mt-5'>
                                <h6 className='registration-title mb-4 mt-5'>Verify your account</h6>

                                <Form onSubmit={submit}>
                                    <Form.Group>
                                        <Form.Control type="text" placeholder="OTP" className='mb-4'
                                            onChange={handleChange} value={user.token} name='token'
                                        />
                                    </Form.Group>
                                </Form>
                                <div className='text-center'>
                                    <GreenDashboardButton text='Submit' loading={props.loading} />
                                    <p className='mt-5 text-center card-small login-link'>Did not get the OTP? REESEND</p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            <SetSecurityQuestionModal show={showSecurity} close={handleClose} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.verifyPhone.isLoading,
        data: state.verifyPhone.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        verify_user_phone: (user) => dispatch(auth.verify_user_phone(user)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal)