import axios from 'axios';
import config from '../config';
import { userActions } from "../actions/creators.js";
import { toast, Flip } from 'react-toastify';
import { history } from '../store/history';

const baseUrl = `${config.apiUrl}`;

const investment = {
    // To list all available investment product
    get_all_product: () => {
        return async dispatch => {
            dispatch(userActions.listAllProductsRequest());

            await axios.get(`${baseUrl}/savinvest/investment_products`)
                .then(res => {
                    dispatch(userActions.listAllProductsSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.listAllProductsSuccess());
                });
        }
    },

    view_product_detail: (id) => {
        return async dispatch => {
            dispatch(userActions.viewProductDetailRequest());

            await axios.get(`${baseUrl}/savinvest/investment_products/${id}`)
                .then(res => {
                    dispatch(userActions.viewProductDetailSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.listAllProductsSuccess());
                });
        }
    },

    subscribe: (payload) => {
        const carts = payload.map((res, i) => ({
            investment_cycle_id: res.investment_cycle_id,
            user_id: res.user_id,
            slots: res.slots,
            recurrent_subscription: res.recurrent_subscription,
            account_no: res.account_no,
            investment_product_id: res.investment_product_id,
            trans_ref: res.trans_ref,
            transaction_pin: res.transaction_pin,
        }))

        return async dispatch => {
            dispatch(userActions.subscriptionRequest());

            await axios.post(`${baseUrl}/savinvest/investment_subscriptions`, { carts })
                .then(res => {
                    dispatch(userActions.subscriptionSuccess(res.data.data));
                    toast.success("Investment subscription successful", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                    localStorage.removeItem('products');
                    history.push('/dashboard')
                })
                .catch(error => {
                    dispatch(userActions.subscriptionFailure());
                    alert(error.response.data.response_message)
                });
        }
    },

    list_subscription: (id) => {
        return async dispatch => {
            dispatch(userActions.listSubscriptionRequest());

            await axios.get(`${baseUrl}/savinvest/user/investment_subscriptions/${id}`)
                .then(res => {
                    dispatch(userActions.listSubscriptionSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.listSubscriptionSuccess());
                });
        }
    },

    investment_history: (subscription_id) => {
        return async dispatch => {
            dispatch(userActions.investmentHistoryRequest());

            await axios.get(`${baseUrl}/savinvest/histories/investment_subscriptions/${subscription_id}`)
                .then(res => {
                    dispatch(userActions.investmentHistorySuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.investmentHistorySuccess());
                });
        }
    },

    get_all_investment: (user_id) => {
        return async dispatch => {
            dispatch(userActions.listAllProductsRequest());

            await axios.get(`${baseUrl}/savinvest/savings_investment_analysis/${user_id}`)
                .then(res => {
                    dispatch(userActions.listAllProductsSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.listAllProductsSuccess());
                });
        }
    },
}

export default investment;