import axios from 'axios';
import config from '../config';
import { userActions } from "../actions/creators.js";
import { toast, Flip } from 'react-toastify';
import { history } from '../store/history';

const baseUrl = `${config.apiUrl}`;

const settings = {
    // To update a user's profile
    update_profile: (update, token, user_id) => {
        return async dispatch => {
            dispatch(userActions.updateProfileRequest());

            await axios.patch(`${baseUrl}/user/u/update-profile/${user_id}`, update, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(res => {
                    dispatch(userActions.updateProfileSuccess(res.data.data));
                    toast.success('Profile updated successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                })
                .catch(error => {
                    dispatch(userActions.updateProfileFailure());
                    toast.error('Error', {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                });
        }
    },

    // To update a user's profile-picture
    update_profile_picture: (profile_photo, token, user_id) => {
        return async dispatch => {
            const formData = new FormData();
            formData.append('image', profile_photo);

            dispatch(userActions.updateProfilePictureRequest());

            await axios.post(`${baseUrl}/user/u/${user_id}/profile-picture`, formData, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(res => {
                    dispatch(userActions.updateProfilePictureSuccess(res.data.data));
                    toast.success('Profile Photo updated successfully', {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                })
                .catch(error => {
                    dispatch(userActions.updateProfilePictureFailure());
                    toast.error('Error', {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                });
        }
    },

    // Change Password
    change_password: (change, token, user_id) => {
        return async dispatch => {
            dispatch(userActions.changePasswordRequest());
            await axios.post(`${baseUrl}/user/users/password/reset/${user_id}`, change, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                dispatch(userActions.changePasswordSuccess());
                toast.success("Your password has been updated successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            }).catch(error => {
                dispatch(userActions.changePasswordFailure());
                toast.error("Your Old password is not correct!", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })

            })
        }
    },

    // Set transaction pin
    set_pin: (pin, token, user_id) => {
        return async dispatch => {
            dispatch(userActions.setPinRequest());
            await axios.post(`${baseUrl}/user/users/set-transaction-pin/${user_id}`, pin, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                dispatch(userActions.setPinSuccess());
                toast.success("Transaction Pin Set Succesfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                });
            }).catch(error => {
                dispatch(userActions.setPinFailure());
                toast.error("Error", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            })
        }
    },

    // To update the user object
    update_user_object: (id) => {
        return async dispatch => {
            await axios.get(`${baseUrl}/user/users-profile/${id}`).then(res => {
                dispatch(userActions.updateUserObject(res.data.data));
            })
        }
    }
}

export default settings;