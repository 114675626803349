export const userConstants = {
    LIST_ALL_PRODUCTS_REQUEST: 'LIST_ALL_PRODUCTS_REQUEST',
    LIST_ALL_PRODUCTS_SUCCESS: 'LIST_ALL_PRODUCTS_SUCCESS',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    VERIFY_LOGIN_REQUEST: 'VERIFY_LOGIN_REQUEST',
    VERIFY_LOGIN_SUCCESS: 'VERIFY_LOGIN_SUCCESS',
    VERIFY_LOGIN_FAILURE: 'VERIFY_LOGIN_FAILURE',

    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    VERIFY_PHONE_REQUEST: 'VERIFY_PHONE_REQUEST',
    VERIFY_PHONE_SUCCESS: 'VERIFY_PHONE_SUCCESS',
    VERIFY_PHONE_FAILURE: 'VERIFY_PHONE_FAILURE',

    ALL_SECURITY_QUESTIONS_REQUEST: 'ALL_SECURITY_QUESTIONS_REQUEST',
    ALL_SECURITY_QUESTIONS_SUCCESS: 'ALL_SECURITY_QUESTIONS_SUCCESS',

    SUBMIT_QUESTION_REQUEST: 'SUBMIT_QUESTION_REQUEST',
    SUBMIT_QUESTION_SUCCESS: 'SUBMIT_QUESTION_SUCCESS',


    VIEW_PRODUCT_DETAIL_REQUEST: 'VIEW_PRODUCT_DETAIL_REQUEST',
    VIEW_PRODUCT_DETAIL_SUCCESS: 'VIEW_PRODUCT_DETAIL_SUCCESS',

    SUBSCRIPTION_REQUEST: 'SUBSCRIPTION_REQUEST',
    SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
    SUBSCRIPTION_FAILURE: 'SUBSCRIPTION_FAILURE',

    LIST_SUBSCRIPTION_REQUEST: 'LIST_SUBSCRIPTION_REQUEST',
    LIST_SUBSCRIPTION_SUCCESS: 'LIST_SUBSCRIPTION_SUCCESS',

    INVESTMENT_HISTORY_REQUEST: 'INVESTMENT_HISTORY_REQUEST',
    INVESTMENT_HISTORY_SUCCESS: 'INVESTMENT_HISTORY_SUCCESS',

    UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

    UPDATE_PROFILE_PICTURE_REQUEST: 'UPDATE_PROFILE_PICTURE_REQUEST',
    UPDATE_PROFILE_PICTURE_SUCCESS: 'UPDATE_PROFILE_PICTURE_SUCCESS',
    UPDATE_PROFILE_PICTURE_FAILURE: 'UPDATE_PROFILE_PICTURE_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    SET_PIN_REQUEST: 'SET_PIN_REQUEST',
    SET_PIN_SUCCESS: 'SET_PIN_SUCCESS',
    SET_PIN_FAILURE: 'SET_PIN_FAILURE',

    UPDATE_USER_OBJECT: 'UPDATE_USER_OBJECT',

    ACCOUNT_BALANCE_REQUEST: 'ACCOUNT_BALANCE_REQUEST',
    ACCOUNT_BALANCE_SUCCESS: 'ACCOUNT_BALANCE_SUCCESS',

    SUBMIT_BVN_REQUEST: 'SUBMIT_BVN_REQUEST',
    SUBMIT_BVN_SUCCESS: 'SUBMIT_BVN_SUCCESS',
    SUBMIT_BVN_FAILURE: 'SUBMIT_BVN_FAILURE',

    VERIFY_BVN_REQUEST: 'VERIFY_BVN_REQUEST',
    VERIFY_BVN_SUCCESS: 'VERIFY_BVN_SUCCESS',

    UPLOAD_DOCUMENT_REQUEST: 'UPLOAD_DOCUMENT_REQUEST',
    UPLOAD_DOCUMENT_SUCCESS: 'UPLOAD_DOCUMENT_SUCCESS',

}