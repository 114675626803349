import React from 'react'
import { Card } from 'react-bootstrap'

export const WorksCard = (props) => {
    return (
        <>
            <Card className='text-center mb-5 works-card'>
                <img src={props.image} className='card-img mt-5' alt='work'/>
                <Card.Body>
                    <Card.Title className='card-price' >{props.title}</Card.Title>
                    <p >
                        {props.text}
                    </p>
                </Card.Body>
            </Card>
        </>
    )
}
