import { userConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
}

function verifyLogin(state = initialState, action) {
    switch (action.type) {
        case userConstants.VERIFY_LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case userConstants.VERIFY_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        case userConstants.UPDATE_USER_OBJECT:
            return {
                ...state,
                isVerifying: false,
                data: action.payload,
            };
        case userConstants.VERIFY_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state
    }
}

export default verifyLogin;