import React from 'react'
import { Modal } from 'react-bootstrap'
import {OrangeButtonLong } from '../../GetrivestButtons'

export const AccountSuccessModal = (props) => {
    return (
        <Modal show={props.show} onHide={props.close} size='lg' className='mt-5'>
            <Modal.Body className='text-center p-5'>
                <img src='/images/confetti.png' alt='success' className='img-fluid ' width='200' />
                <h4 className='registration-title m-3'>Account Created successfully</h4>
                <p>Now that your account has been created successfully, let banking begin</p>
                <OrangeButtonLong text='Get Started'/>
            </Modal.Body>
        </Modal>
    )
}
