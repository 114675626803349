import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import { history } from '../../store/history'
import { GreenButton, WorksButton } from '../GetrivestButtons'

export const Banner = () => {
    return (
        <div style={{backgroundColor:'#F6F6F7'}}>
            <Carousel controls={false}>
                <Carousel.Item>
                    <Container fluid className='caro-height' >
                        <div className='banner3' style={{ backgroundImage:  "url(/images/farm2.png)" }}>
                        </div>
                        <Row>
                            <Col xs={12} sm={12} lg={5} md={5}>
                            </Col>
                            <Col xs={12} sm={12} lg={7} md={7} className='mt-5 banner-bod'>
                                <h2 className='banner-title2 pl-3 pr-3'>PLANTING: HARVEST TIME IS PROFIT TIME</h2>
                                <p className='banner-text2 pl-3 pr-3'>A secure future means you did something right TODAY.</p>

                                <Container className='pl-lg-5 ml-lg-5 mt-3'>
                                    <Row>
                                        <Col lg={4} sm={5} xs={5} > <GreenButton text='LEARN MORE' click={() => history.push('/about')} /></Col>
                                        <Col lg={4} sm={5} xs={5}  > <WorksButton text='HOW IT WORKS' /></Col>
                                        <Col lg={4} ></Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>

                <Carousel.Item>
                    <Container fluid className='caro-height'>
                        <div className='banner3' style={{ backgroundImage:"url(/images/chickens_circle.png)"  }}>
                        </div>
                        <Row>
                            <Col xs={12} sm={12} lg={5} md={5}>
                            </Col>
                            <Col xs={12} sm={12} lg={7} md={7} className='mt-5 banner-bod'>
                                <h2 className='banner-title2 pl-3 pr-3'>POULTRY: WE HAVE SEEN THE FUTURE AND IT SAYS “CHICKENS!!!!</h2>
                                <p className='banner-text2 pl-3 pr-3'>A secure future means you did something right TODAY.</p>

                                <Container className='pl-lg-5 ml-lg-5 mt-3'>
                                    <Row>
                                        <Col lg={4} sm={5} xs={5} > <GreenButton text='LEARN MORE' click={() => history.push('/about')} /></Col>
                                        <Col lg={4} sm={5} xs={5}  > <WorksButton text='HOW IT WORKS' /></Col>
                                        <Col lg={4} ></Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>


                <Carousel.Item>

                    <Container fluid className='caro-height' >
                        <div className='banner3' style={{ backgroundImage: "url(/images/farmer_ipad.png)" }}>
                        </div>
                        <Row>
                            <Col xs={12} sm={12} lg={5} md={5}>
                            </Col>
                            <Col xs={12} sm={12} lg={7} md={7} className='mt-5 banner-bod'>


                                <h3 className='banner-title2 pl-3 pr-3'>PROCESSING: WE ARE INVOLVED, FROM START TO FINISH</h3>
                                <p className='banner-text2 pl-3 pr-3'>A secure future means you did something right TODAY.</p>

                                <Container className='pl-lg-5 ml-lg-5 mt-3'>
                                    <Row>
                                        <Col lg={4} sm={5} xs={5} > <GreenButton text='LEARN MORE' click={() => history.push('/about')} /></Col>
                                        <Col lg={4} sm={5} xs={5}>
                                            <WorksButton text='HOW IT WORKS' />
                                        </Col>
                                        <Col lg={4} ></Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Item>
            </Carousel>

        </div>

    )
}
