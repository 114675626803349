import { userConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
}

function listAllProducts(state = initialState, action) {
    switch (action.type) {
        case userConstants.LIST_ALL_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case userConstants.LIST_ALL_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        default:
            return state
    }
}

export default listAllProducts;