import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

export const AboutSectionThree = () => {
    return (
        <Container fluid className='mt-5'>
            <Row>
                <Col lg={4} md={4} className='about-bg-1 p-5 text-white'>
                    <img src='/images/vision.png' alt='vision' className='pl-lg-5' />
                    <h4 className='pl-lg-5 mt-5'>Our Vision</h4>
                    <p className='pl-lg-5'>
                        Aiming to be the chief-driver of economic <br /> sustainability through investment in various <br /> agricultural and agro-allied industries.
                    </p>
                </Col>
                <Col lg={4} md={4} className='about-bg-2 p-5'>
                    <img src='/images/mission.png' alt='mission' className='pl-lg-5' />
                    <h4 className='pl-lg-5 mt-5 font-weight-bold'>Our Mission</h4>
                    <p className='pl-lg-5'>
                        To combine the best hands, assets and practices <br /> in the delivery of first-rate services to our customers.
                    </p>
                </Col>
                <Col lg={4} md={4} className='about-bg-1 p-5 text-white'>
                    <img src='/images/vision.png' alt='vision' className='pl-lg-5' />
                    <h4 className='pl-lg-5 mt-5'>Our Values</h4>
                    <p className='pl-lg-5'>
                    <img src='/images/mission.png' alt='passion' width='30' />  PASSION <br/>
                    <img src='/images/mission.png' alt='passion' width='30' />   QUALITY <br/>
                    <img src='/images/mission.png' alt='passion' width='30' />   TECHNOLOGICAL INNOVATIONS <br/>
                    <img src='/images/mission.png' alt='passion' width='30' />   CREATIVITY <br/>
                    <img src='/images/mission.png' alt='passion' width='30' />   FLEXIBILITY<br/>
                    <img src='/images/mission.png' alt='passion' width='30' />   ENVIRONMENTAL AND ECO-FRIENDLY PRACTICES

                    </p>
                </Col>
            </Row>
        </Container>
    )
}
