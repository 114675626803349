import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { GreenButton, PlainGreenButton } from '../GetrivestButtons';
import RegistrationModal from './Auth/RegistrationModal';
import LoginModal from './Auth/LoginComponent';
import investment from '../../services/investmentService';
import { connect } from 'react-redux';
import { Loader } from '../Loader';
import { numberFormatter } from '../numberFormatter';
import { PinModal } from './PinModal';

const CartBody = (props) => {
    const [show, setShow] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [cart, setCart] = useState([]);
    const [pin, setPin] = useState('');
    const [showPin, setShowPin] = useState(false);
    let carts;

    useEffect(() => {
        async function init() {
            const data = await localStorage.getItem('products');
            setCart(JSON.parse(data));
        }
        init();
    }, []);

    function handleChange(e) {
        setPin(e.target.value)
    };

    function handleShowPin() {
        setShowPin(true)
    };

    function handleClosePin() {
        setShowPin(false)
    };

    // To delete a product from cart
    async function removeProduct(id) {
        let storageProducts = JSON.parse(localStorage.getItem('products'));
        let products = storageProducts.filter(product => product.investment_cycle_id !== id);
        localStorage.setItem('products', JSON.stringify(products));
        await setCart(products);
    };

    const handleClose = () => setShow(false);

    const getProducts = cart ? cart : []

    carts = getProducts.map((res, i) => ({
        investment_cycle_id: res.investment_cycle_id,
        user_id: props.user.token ? props.user.id : '',
        slots: res.slots,
        recurrent_subscription: res.recurrent_subscription,
        account_no: props.user.bank_account ? props.user.bank_account[0].account_number : '',
        investment_product_id: res.investment_product_id,
        trans_ref: props.user.token ? `${props.user.id}` + Math.floor(Math.random() * 900000000000000000) : '',
        transaction_pin: pin,
        name: res.name,
        roi: res.roi,
        cost_per_slot: res.cost_per_slot,
        min_slots: res.min_slots,
        slot_left: res.slot_left,
        period: res.period,
        start_date: res.start_date,
        end_date: res.end_date
    }))
    console.log(carts)
    let total = carts.reduce((sum, carts) => sum + Number(carts.cost_per_slot) * carts.slots, 0)


    function decrementCount(item, i) { // index will be the key value 
        if (carts[i].slots <= carts[i].min_slots) {
            alert('lesser')
        } else {
            carts[i].slots--;
            setCart(carts)
        }
    };

    function incrementCount(item, i) { // index will be the key value    
        if (carts[i].slots >= carts[i].slot_left) {
            alert('You cannot buy slots greater than the total number of available slots')
        } else {
            carts[i].slots++;
            setCart(carts)
        }

    };

    function handleShow() {
        if (props.user.token) {
            setShowPin(true)
        } else {
            setShow(true)
        }
    };

    function handleShowLogin() {
        setShowLogin(true)
        setShow(false)
    }
    function handleCloseLogin() {
        setShowLogin(false)
    }

    function submit() {
        if (pin) {
            props.subscribe(carts);
        }
    }

    return (
        <Container>
            {props.loading ?
                <Loader />
                :
                ''
            }
            {getProducts.length !== 0 ?
                <>
                    {getProducts.map((data, i) => (
                        <Row key={i}>
                            <Col lg='1'></Col>

                            <Col lg='10' className='cart-body mt-5 p-3'>
                                <img alt='delete' src='/images/close_icon.png' className='text-right delete-cart' onClick={() => removeProduct(data.investment_cycle_id)} />
                                <Container>
                                    <Row className='align-items-center'>
                                        <Col lg='2'>
                                            <img src='/images/avatar.jpg' alt='cart' className='card-img' />
                                            <p className='m-0 package-modal-period font-weight-bolder text-danger'>Min slots: {data.min_slots}</p>
                                        </Col>
                                        <Col lg='6'>
                                            <span className='package-modal-title'>{data.name}</span>
                                            <div>
                                                <span className='card-small'> ROI - </span>
                                                <span className='package-modal-roi'>{data.roi}%</span>
                                            </div>
                                            <span className='package-modal-unit'>NGN {Number(data.cost_per_slot).toLocaleString()}/Unit</span>
                                            <span className='package-modal-date'>{data.start_date} - {data.end_date}</span>
                                            <span className='package-modal-period font-weight-bold'> ({data.period} days)</span>
                                        </Col>

                                        <Col lg='4' className='text-right'>
                                            <img src='/images/minus icon.png' alt='minus' className='minus-icon' onClick={(data) => decrementCount(data, i)} />
                                            <span className='font-weight-bolder ml-3 mr-3 mt-2'> {data.slots} </span>
                                            <img src='/images/add_icon.png' alt='minus' className='minus-icon' onClick={(data) => incrementCount(data, i)} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>

                            <Col lg='1'></Col>
                        </Row>
                    ))}
                </>
                :
                <Row>
                    <Col lg='1'></Col>
                    <Col lg='10' className='cart-body mt-5 p-3'>
                        <p className='text-center'>Your cart is empty</p>
                    </Col>
                    <Col lg='1'> </Col>
                </Row>
            }


            <Container >
                <Row>
                    <Col lg='1'></Col>
                    <Col lg='10' className='text-right'>
                        <p className='m-0'>TOTAL INVESTMENT VALUE:</p>
                        <p className='font-weight-bolder getrivest-orange '>NGN {numberFormatter(total)}</p>
                    </Col>
                    <Col lg='1'></Col>
                </Row>

                <Row>
                    <Col lg='1'></Col>
                    <Col lg='10' >
                        <Row className='mt-3 mb-5'>
                            <Col lg='6' className='text-left'>
                                <PlainGreenButton text='Continue Shopping' />
                            </Col>
                            <Col lg='6' className='text-right'>
                                <GreenButton text='Checkout' click={handleShow} />
                            </Col>

                        </Row>
                    </Col>
                    <Col lg='1' ></Col>
                </Row>

            </Container>
            <RegistrationModal show={show} close={handleClose} openNew={handleShowLogin} />
            <LoginModal show={showLogin} close={handleCloseLogin} />
            <PinModal show={showPin} close={handleClosePin} value={pin} name='pin' submit={submit} change={handleChange} />
        </Container>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.subscribe.isLoading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        subscribe: (id) => dispatch(investment.subscribe(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartBody);