import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { LearnButton} from '../GetrivestButtons'

export const AboutBanner = () => {
    return (
        <Container fluid className='banner' style={{ backgroundImage: "url(/images/aboutus_jumbotron.png)" }}>
            <Row>
                <Col xs={12} sm={12} lg={5} md={5}>
                </Col>
                <Col xs={12} sm={12} lg={7} md={7} className='mt-5 banner-bod'>
                    <h2 className='banner-title'>About Us</h2>
                    <p className='banner-text'>
                       Aiming to be the chief-driver of economic  sustainability through investment in various  agricultural and agro-allied industries.
                </p>
                    <Container className='pl-lg-5 ml-lg-5 mt-3'>
                        <Row>
                            <Col lg={2} sm={3} xs={2}  ></Col>
                            <Col lg={4} sm={5} xs={5} > <LearnButton text='LEARN MORE' /></Col>
                            <Col lg={4} ></Col>
                        </Row>
                    </Container>


                </Col>
            </Row>
        </Container>
    )
}
