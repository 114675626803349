import React from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { connect } from 'react-redux'
import { DashboardBanner } from '../../components/Dashboard/DashboardBanner'
import Navigation from '../../components/Navigation'
import EditProfile from '../../components/Settings/EditProfile'
import { InformationBox } from '../../components/Settings/InformationBox'
import LinkBvn from '../../components/Settings/LinkBvn'
import Security from '../../components/Settings/Security';
import UploadDocument from '../../components/Settings/UploadDocument';

const Settings = (props) => {
    return (
        <div>
            <Navigation />
            <DashboardBanner {...props} text='Settings' image='/images/header_dashboard.png' />

            <Container className='mt-4 mb-4'>
                <Row>
                    <Col md={8} lg={8} sm={12} xs={12} className='mt-4'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={5} className='tab-settings'>
                                    <Nav variant="pills" className="flex-column pt-3">
                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="first">
                                                <img src='/images/edit_profile.png' alt='edit-profile' className='img-fluid' width='30' />
                                                {' '}   Edit Profile
                                                </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="second">
                                                <img src='/images/bvn.png' alt='link-bvn' className='img-fluid' width='30' />
                                                {' '} Link BVN</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="third">
                                                <img src='/images/bvn.png' alt='link-bvn' className='img-fluid' width='30' />
                                                {' '} Upload Document</Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="fourth">
                                                <img src='/images/security_orange.png' alt='security' className='img-fluid' width='30' />
                                                {' '} Security
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="fifth">
                                                <img src='/images/notification_orange.png' alt='security' className='img-fluid' width='30' />
                                                {' '} Notifications</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="sixth">
                                                <img src='/images/help_orange.png' alt='security' className='img-fluid' width='30' />
                                                {' '}   Help</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className='mb-3'>
                                            <Nav.Link eventKey="seventh">
                                                <img src='/images/getri_about_orange.png' alt='security' className='img-fluid' width='30' />
                                                {' '}  About</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={7} >
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <EditProfile />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <LinkBvn />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <UploadDocument/>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fourth">
                                            <Security />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            Notifications here
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>

                    <Col md={4} lg={4} sm={12} xs={12} className='mt-4'>
                        <InformationBox />
                    </Col>
                </Row>

            </Container>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
    };
};

export default connect(mapStateToProps, null)(Settings);
