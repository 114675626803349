import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import config from '../../config';
import { SmallGreenButton } from '../GetrivestButtons';
import { Loader } from '../Loader';
import axios from 'axios';
import { toast, Flip } from 'react-toastify';
import { useFlutterwave } from 'flutterwave-react-v3';
import account from '../../services/accountService';

const baseUrl = `${config.apiUrl}`;
const test_key = process.env.REACT_APP_FLUTTERWAVE_TEST_KEY

const FundAccount = (props) => {
    const [getriRef, setGetriRef] = useState('');
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const [initiate, setInitiate] = useState({
        account_number: props.user.bank_account[0].account_number,
        user_id: props.user.id,
        amount: '',
        narration: "Fund account: " + props.user.bank_account[0].account_number,
        request_id: Math.floor(Math.random() * 900000000000000000)
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setInitiate((initiate) => ({ ...initiate, [name]: value }));
    }


    function initiateFund(e) {
        e.preventDefault();
        if (initiate.amount >= 100) {
            axios.post(`${baseUrl}/accounts/${props.user.bank_account[0].account_number}/fund_with_flw/initiate`, initiate).then((res) => {
                setGetriRef(res.data.data.transaction_ref);
                setConfirm(true);
            }).catch(error => {
                if (error.response.data.response_code === 'ACM007') {
                    toast.error("Error", { position: toast.POSITION.TOP_RIGHT, transition: Flip, });
                }
            })
        }

    };

    const fund = {
        custom_logo: 'http://getripay.lending.ng/images/yellow-logo.svg',
        custom_title: 'Fund Your GetriPay Account: ' + props.user.bank_account[0].account_number,
        account_no: props.user.bank_account[0].account_number,
        key: test_key, // RavePay PUBLIC KEY
        phone: props.user.phone_number,
        firstname: props.user.first_name,
        lastname: props.user.last_name,
        email: props.user.email,
        txref: getriRef
    };

    const config = {
        public_key: fund.key,
        tx_ref: getriRef,
        amount: initiate.amount,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: fund.email,
            phonenumber: fund.phone,
            name: fund.account_no + ': ' + fund.firstname + ' ' + fund.lastname,
        },
        customizations: {
            title: fund.custom_title,
            description: 'Account Fundinng',
            logo: fund.custom_logo,
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    const close = () => {
        setTimeout(() => {
            document.getElementsByName('checkout')[0].setAttribute('style', 'z-index: -1; opacity: 0')
        }, 1000)
    };

    return (
        <div className='fund-account m-3 p-3'>
            {
                loading
                    ?
                    <Loader />
                    :
                    ''
            }
            <p>Enter the amount you would like to fund</p>
            <Form onSubmit={initiateFund}>
                <Form.Control type='number' placeholder='Amount' value={initiate.amount} name='amount' onChange={handleChange} />
                <div className='mt-3'>
                    <SmallGreenButton text='Continue' />
                </div>
            </Form>


            <Modal centered
                show={confirm === true ? true : confirm === false ? false : ''}
                onHide={() => setConfirm(false)} >
                <Modal.Body>
                    <p>
                        Are you sure you want to fund your account with NGN {initiate.amount} ?
                    </p>

                    <div onClick={() => setConfirm(false)}>
                        <button
                            className='green-button'
                            onClick={() => {
                                handleFlutterPayment({
                                    callback: (response) => {
                                        var status = response.status;

                                        close();
                                        if (status == "successful") {

                                            setLoading(true);

                                            axios.post(`${baseUrl}/accounts/${props.user.bank_account[0].account_number}/fund_with_flw`, {
                                                "account_number": props.user.bank_account[0].account_number,
                                                "user_id": props.user.id,
                                                "amount": initiate.amount,
                                                "narration": "Fund account: " + props.user.bank_account[0].account_number,
                                                "trans_ref": getriRef,
                                                'request_id': initiate.request_id
                                            }).then(function (response) {
                                                if (response.data.response_code === "00") {
                                                    setLoading(false);
                                                    toast.success("Account funding successful", { position: toast.POSITION.TOP_RIGHT, transition: Flip, });
                                                    props.get_account_balance(props.user.bank_account[0].account_number);
                                                } else {
                                                }
                                                if (response.data.response_code === "00") {
                                                    setInitiate({
                                                        account_number: '',
                                                        user_id: '',
                                                        amount: '',
                                                        narration: "Fund account: " + props.user.bank_account[0].account_number,
                                                        request_id: ''
                                                    })
                                                };
                                            })
                                                .catch(function (error) {
                                                    setLoading(false);
                                                });

                                            //Give Value and return to Success page
                                        } else {

                                        }
                                    },
                                    onClose: () => {
                                        // close();
                                    },
                                });
                            }}
                        >
                            Fund Account
                    </button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
};


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_account_balance: (account_no) => dispatch(account.get_account_balance(account_no))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundAccount);