import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { numberFormatter } from '../numberFormatter';
import { InvestmentDetailModal } from './InvestmentDetailModal'

export const InvestmentBox = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const start = new Date()
    const end = new Date(props.end_date)

    // To calculate the time difference of two dates 
    var Difference_In_Time = end.getTime() - start.getTime();

    // To calculate the no. of days between two dates 
    var Difference_In_Days = Math.floor(Difference_In_Time / (1000 * 3600 * 24));

    return (
        <div className='box p-3' onClick={handleShow} style={{ cursor: 'pointer' }}>
            <p className='getrivest-orange mb-4'>{props.name}</p>


            <div className='list-bg p-3' >
                <Container>
                    <Row className='align-items-center'>
                        <Col lg='5'>
                            <img src='/images/avatar.jpg' alt='invest' className='card-img' />
                        </Col>
                        <Col lg='7'>
                            <h5>{props.slots} Units</h5>
                            <p className='getrivest-green card-small font-weight-bolder m-0'>NGN {numberFormatter(props.amount)}</p>

                            <Row>
                                <Col lg='3' sm='3' xs='3'>
                                    <p className='invest-roi card-small p-1 m-0'>ROI</p>
                                </Col>
                                <Col lg='3' sm='3' xs='3'>
                                    <p className='font-weight-bolder'>{props.interest_rate}% </p>
                                </Col>
                                <Col lg='6'></Col>
                            </Row>
                            <Row>
                                <Col lg='6' sm='6' xs='6'>
                                    <p className='invest-left card-small p-1 m-0'>DAYS LEFT</p>
                                </Col>
                                <Col lg='6' sm='6' xs='6'>
                                    <p className='font-weight-bolder'>{Difference_In_Days}days</p>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
                <InvestmentDetailModal show={show} close={handleClose} {...props} diff={Difference_In_Days} />
            </div>

        </div>
    )
}
