import React, { useState } from 'react'
import { Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'
import { connect } from 'react-redux';
import investment from '../services/investmentService';
import { OrangeButtonLong } from './GetrivestButtons'
import { numberFormatter } from './numberFormatter';
import { PackageModal } from './Packages/PackageModal';


const CardComponent = (props) => {

    const [show, setShow] = useState(false);
    const id = props.id;

    const handleClose = () => setShow(false);

    function handleShow() {
        props.view_product_detail(id);
        setShow(true);
    };

    const now = props.investment_cycle ? (props.investment_cycle.slots_taken / props.investment_cycle.slot) * 100 : 0

    const then = props.investment_cycle ? ((props.investment_cycle.slot - props.investment_cycle.slots_taken) / props.investment_cycle.slot) * 100 : 0

    const progressLeft = <ProgressBar>
        <ProgressBar variant="danger" now={now} key={1} label={`${Math.abs(now)}% taken`} />
        <ProgressBar variant="success" now={then} label={`${Math.abs(then)}% left`} key={2} />
    </ProgressBar>

    const disableDate = props.investment_cycle && new Date(props.investment_cycle.end_date) < (new Date) ? true : false

    const start = props.investment_cycle && new Date(props.investment_cycle.start_date)
    const end = props.investment_cycle && props.investment_cycle && new Date(props.investment_cycle.end_date)

    // To calculate the time difference of two dates 
    var Difference_In_Time = props.investment_cycle && end.getTime() - start.getTime();

    // To calculate the no. of days between two dates 
    var investmentPeriod = Math.floor(Difference_In_Time / (1000 * 3600 * 24));

    return (
        <>
            {props.investment_cycle && props.status === 'active' ?
                <Card className='text-center mb-5'>
                    <img src={props.image_url} alt='investment' alt='package' className='card-img mt-4' />
                    {now >= 70 && now !== 100 ?
                        <p className='font-weight-bolder blinking'> {props.investment_cycle.slot - props.investment_cycle.slots_taken} slots left </p>
                        :
                        now === 100.00 ?
                            <p className='font-weight-bolder'>
                                SOLD OUT
         </p>
                            :
                            <p className='font-weight-bolder'> {props.investment_cycle && props.investment_cycle.slot - props.investment_cycle.slots_taken} slots left </p>
                    }

                    <div className=' m-auto'>
                        {/* {(progressInstance)} */}
                        {(progressLeft)}
                    </div>

                    <Card.Body>
                        <Card.Title className='card-title' >{props.name}</Card.Title>
                        <p className='m-0'>{props.category.industry} industry</p>
                        <Container>
                            <Row className='text-left mb-2'>
                                <Col lg={7} sm={7} xs={7} className='card-price'>NGN{numberFormatter(props.investment_cycle.cost_per_slot)}/Unit</Col>
                                <Col lg={5} sm={5} xs={5} className='card-period text-right'>{investmentPeriod} days</Col>
                            </Row>
                            <Row className='text-left'>
                                <Col lg={6} sm={6} xs={6} className='card-small'>Start</Col>
                                <Col lg={6} sm={6} xs={6} className='card-small text-right'>End</Col>
                            </Row>
                            <Row className='text-left'>
                                <Col lg={6} sm={6} xs={6} className='card-text'>{new Date(props.investment_cycle.start_date).toDateString()}</Col>
                                <Col lg={6} sm={6} xs={6} className='card-text text-right'>{new Date(props.investment_cycle.end_date).toDateString()}</Col>
                            </Row>
                            <p className='font-weight-bolder m-0 card-period'>ROI - {props.investment_cycle.ROI_percentage}% </p>
                        </Container>
                        <div className='mt-4 mb-3'>
                            <OrangeButtonLong text={now === 100 ? 'SOLD OUT' : 'INVEST NOW'} click={handleShow} disable={now === 100 || disableDate} />
                            <PackageModal show={show} close={handleClose} {...props} period={investmentPeriod} />
                        </div>

                    </Card.Body>
                </Card>
                :
                ''
            }

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        view_product_detail: (id) => dispatch(investment.view_product_detail(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardComponent);