import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { WorksCard } from './WorksCard';

export const SectionThree = () => {
    const data = [
        {
            title: 'Step One',
            text: 'Open a GetriVest account using your BVN OR by providing your name, email and phone number at getrivest.com. ',
            image: '/images/Chicken 1.png',
        },
        {
            title: 'Step Two',
            text: 'Select your preferred investment option and number of slots.',
            image: '/images/Chicken 1.png',
        },
        {
            title: 'Step Three',
            text: 'Wait for the maturity date of your chosen investment type and cash out at harvest.',
            image: '/images/Chicken 1.png',
        }
        
    ];
    return (
        <div className='section-three mt-5 text-center' id='works'>
            <h2>How it works</h2>

            <Container className='mt-5'>
                <Row>
                    {data.map((data, i) => (
                        <Col md={6} lg={4} sm={12} xs={12} key={i}>
                            <WorksCard {...data} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}
