import React, { useState, useEffect } from 'react';
import { Badge, Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import RegistrationModal from './Dashboard/Auth/RegistrationModal';
import LoginModal from './Dashboard/Auth/LoginComponent';
import { OrangeButton } from './GetrivestButtons';
const CrossStorage = require('cross-storage-plus');

function Navigation(props) {
  const [show, setShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [value, setValue] = useState(0);
  let store;

  function handleShow() {
    setShow(true)
    setShowLogin(false)
  }
  function handleClose() {
    setShow(false)
  }

  function handleShowLogin() {
    setShowLogin(true)
    setShow(false)
  }
  function handleCloseLogin() {
    setShowLogin(false)
  };

  async function storage() {
    store = await CrossStorage.initializeClient('https://getripay.lending.ng/index.html');
    store.removeItem('persist:root');
    store.removeItem('user_id');
    store.removeItem('token');
    store.removeItem('kyc');
    store.removeItem('account');
    store.removeItem('register_token');
    store.removeItem('email');
    localStorage.clear();
  };

  function logout() {
    storage();
  }

  useEffect(() => {
    const cart = localStorage.getItem('products')
    const newVal = cart ? JSON.parse(cart).length : 0
    setValue(newVal)

  }, [value])

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="white" variant="light" sticky='top' >
        <Navbar.Brand href="/">
          <img src='/images/logo.png' className='logo' alt='logo' />
        </Navbar.Brand>
        {props.user.token &&
          <Nav.Link href="#" className=' getrivest-green font-weight-bolder '>Welcome {props.user.first_name} {props.user.last_name}</Nav.Link>
        }

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='ml-auto align-items-center'>
            <Nav.Link href="/" className='getri-nav'>Home</Nav.Link>
            <Nav.Link href="/about" className='getri-nav'>About </Nav.Link>
            <Nav.Link href="/packages" className='getri-nav'>Packages</Nav.Link>
            <Nav.Link href="/faqs" className='getri-nav'>FAQs</Nav.Link>
            {props.user.token ?
              <Nav.Link href='/' onClick={logout} className='getri-nav'>Logout</Nav.Link>
              :
              <Nav.Link className='getri-nav' onClick={handleShowLogin}>Login</Nav.Link>
            }
            {props.user.token ?
              <Nav.Link href="/dashboard" className='mr-3 ml-2'>
                <OrangeButton text='DASHBOARD' />
              </Nav.Link>
              :
              ''
            }
            <Nav.Link href="/cart" className='getri-nav'>
              <img src='/images/cart_grey.png' alt='cart' className='img-fluid' width='25' />
              {value &&
                <Badge variant="danger">{value}</Badge>
              }
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <RegistrationModal show={show} close={handleClose} open={handleShowLogin} />
      <LoginModal show={showLogin} close={handleCloseLogin} openNew={handleShow} />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data
  };
};

export default connect(mapStateToProps, null)(Navigation);