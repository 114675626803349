import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Container, Modal, Row, Col, Form } from 'react-bootstrap';
import auth from '../../../services/authService';
import { GreenDashboardButton } from '../../GetrivestButtons';
import { AccountSuccessModal } from './AccountSuccessModal.js';
import  VerificationModal  from './VerificationModal';

const RegistrationModal = (props) => {
    const [showNew, setShowNew] = useState(false);
    const [validated, setValidated] = useState(false);
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        c_password: "",
        phone_number: "",
        parent_id: "",
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    };
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (user.first_name && user.last_name && user.username && user.email && user.password && user.c_password && user.phone_number) {
            props.register_user(user)
            setShowNew(true)    
        }
    };

    return (
        <>
            <Modal show={props.show === true && props.data.success !== true} onHide={props.close} size='xl' centered>
                <Modal.Body className='p-0'>
                    <Container fluid className='m-0 p-0'>
                        <Row>
                            <Col lg='7' md='12' sm='12' xs='12'>
                                <img src='/images/pattern_bg.png' alt='pattern' className='img-fluid reg-image' />
                            </Col>
                            <Col lg='5' md='12' sm='12' xs='12' className='pl-4 pr-5 pt-3 pb-5 mt-2'>
                                <h5 className='registration-title mb-4'>Create a GetriPay account</h5>

                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group controlId="validationCustom01">
                                        <Form.Control type="text" placeholder="First Name" required onChange={handleChange}
                                            name='first_name' value={user.first_name} />
                                        <Form.Control.Feedback type="invalid">  Please enter your first name. </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="validationCustom02">
                                        <Form.Control type="text" placeholder="Last Name" required onChange={handleChange}
                                            name='last_name' value={user.last_name} />
                                        <Form.Control.Feedback type="invalid">  Please enter your last name. </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="validationCustom03">
                                        <Form.Control type="text" placeholder="Username" required onChange={handleChange}
                                            name='username' value={user.username} />
                                        <Form.Control.Feedback type="invalid">  Please enter your username. </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="validationCustom04">
                                        <Form.Control type="email" placeholder="Email address" required onChange={handleChange}
                                            name='email' value={user.email} />
                                        <Form.Control.Feedback type="invalid">  Please enter your email address. </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="validationCustom05">
                                        <Form.Control type="password" placeholder="Password" required onChange={handleChange}
                                            name='password' value={user.password} />
                                        <Form.Control.Feedback type="invalid">  Please enter your password. </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group controlId="validationCustom06">
                                        <Form.Control type="password" placeholder="Confirm Password" required onChange={handleChange}
                                            name='c_password' value={user.c_password}
                                        />
                                        <Form.Control.Feedback type="invalid">  Passwords must match. </Form.Control.Feedback>

                                    </Form.Group>

                                    <Form.Group controlId="validationCustom07">
                                        <Form.Control type="number" placeholder="Phone Number" required onChange={handleChange}
                                            name='phone_number' value={user.phone_number} />
                                        <Form.Control.Feedback type="invalid">  Please enter your 11 digit phone number. </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Control type="text" placeholder="Referral Code(optional)" onChange={handleChange}
                                        name='parent_id' value={user.parent_id}
                                    />

                                    <Form.Group controlId="validationCustom09">
                                        <Form.Check required type="checkbox"
                                            label="I agree with the terms and conditions of GetriPay"
                                            feedback="You must agree before submitting."
                                        />
                                    </Form.Group>


                                </Form>
                                <div className='text-center'>
                                    <GreenDashboardButton text='CONTINUE' click={handleSubmit} loading={props.loading} />
                                    <span onClick={props.open} className='m-0 text-center card-small login-link'>Already have a GetriPay account? Login</span>
                                </div>


                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <AccountSuccessModal />
            </Modal>
            <VerificationModal show={showNew} />
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        loading: state.register.isLoading,
        data: state.register.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        register_user: (user) => dispatch(auth.register_user(user)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(RegistrationModal)