import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { history } from '../store/history'
import { GreenButton, GreenDashboardButton } from './GetrivestButtons'

export const Footer = () => {
    return (
        <>
            <div className='footer' style={{ backgroundImage: "url(/images/orange_bg.png)" }} >
                <Container>
                    <Row>
                        <Col lg={12} className='text-center mt-lg-5'>
                            <h4 className='text-white'>You couldn’t be in more secure hands</h4>
                            <p className='text-white m-0'>
                                -	All investments are fully insured . <br />
                            -	All transactions are carried out using secured channels.<br />
                            -	Absolute confidentiality of all information shared with us is guaranteed.
                            </p>
                            <div className='mt-3 mb-3' >
                                <GreenButton text='Get Started' click={()=>history.push('/packages')}  />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container fluid >
                <Row className='footer-bottom'>
                    <Col lg={8} md={8} sm={8} xs={8}></Col>
                    <Col xs={3} lg={3} md={3} sm={3} className='text-right'>
                        <img src='/images/Chicken 1.png' alt='footer' />
                    </Col>
                    <Col xs={1} lg={1}>

                    </Col>
                </Row>
            </Container>
        </>

    )
}
