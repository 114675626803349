import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const PackagesBanner = () => {
    return (
        <Container fluid className='banner2' style={{ backgroundImage: "url(/images/packages_jumbotron.png)" }}>
            <Row>
                <Col xs={12} sm={12} lg={5} md={5} className=' mt-lg-3'>
                <h2 className='banner-title-package'>Our Packages</h2>
                    {/* <p className='banner-text-package'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.  Lorem ipsum dolor sit amet,
                        consectetur adipiscing elit. Nulla quis lorem ut libero.
                    </p> */}
                </Col>
                <Col xs={12} sm={12} lg={7} md={7} >
                   
                </Col>
            </Row>
        </Container>
    )
}
