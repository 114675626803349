import axios from 'axios';
import config from '../config';
import { userActions } from "../actions/creators.js";
import { toast, Flip } from 'react-toastify';

const baseUrl = `${config.apiUrl}`;

const auth = {
    // To log in a user
    login_user: (user) => {
        return async dispatch => {
            dispatch(userActions.loginRequest());

            await axios.post(`${baseUrl}/user/users/login`, user)
                .then(res => {
                    dispatch(userActions.loginSuccess(res.data));
                    sessionStorage.setItem('email', (res.data.data.email));
                    sessionStorage.setItem('register_token', (res.data.data.register_token))
                    sessionStorage.setItem('user_id', (res.data.data.user_id))
                })
                .catch(error => {
                    dispatch(userActions.loginFailure());
                });
        }
    },

    verify_login: (answer, answer_id) => {
        return async dispatch => {
            dispatch(userActions.verifyLoginRequest());

            const register_token = sessionStorage.getItem('register_token');
            const email = sessionStorage.getItem('email')
            const user_id = sessionStorage.getItem('user_id')

            await axios.post(`${baseUrl}/user/users/login-web-question-answer-verify`, { answer, answer_id, register_token, email, user_id })
                .then(res => {
                    dispatch(userActions.verifyLoginSuccess(res.data.data));
                    toast.success("Login successful", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                })
                .catch(error => {
                    dispatch(userActions.verifyLoginFailure(error.response.data.response_message));
                    console.log(error.response.data)
                });
        }
    },

    // REGISTRATION //

    // To register a user
    register_user: (payload) => {
        return async dispatch => {
            dispatch(userActions.registerRequest());

            await axios.post(`${baseUrl}/user/users/register/web`, payload)
                .then(res => {
                    console.log(res.data)
                    dispatch(userActions.registerSuccess(res.data));
                    if (res.data.response_code === 'USM011') {
                        toast.success("Please check your email/phone for your verification code", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        sessionStorage.setItem('register_token', (res.data.data.register_token));
                        sessionStorage.setItem('user_id', (res.data.data.user_id));
                    }
                })
                .catch(error => {
                    dispatch(userActions.registerFailure());
                    let dataObj = error.response.data.errors
                    let errorMessage = `${dataObj.phone_number ? dataObj.phone_number[0] + ',' : ''}, ${dataObj.c_password ? dataObj.c_password[0] + ',' : ''} ${dataObj.email ? dataObj.email[0] + ',' : ''}   ${dataObj.first_name ? dataObj.first_name[0] + ',' : ''}  ${dataObj.last_name ? dataObj.last_name[0] + ',' : ''}  ${dataObj.email ? dataObj.email[0] + ',' : ''}   ${dataObj.first_name ? dataObj.first_name[0] + ',' : ''}  ${dataObj.username ? dataObj.username[0] + ',' : ''}`
                    console.log(dataObj)
                    if (error.response.data.message === "The given data was invalid.") {
                        toast.error(errorMessage, {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip
                        })
                    } else {
                        toast.error('ERROR', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip
                        })
                    }
                })
        }
    },


    // To verify a user's account/phone number
    verify_user_phone: (user) => {
        return async dispatch => {
            dispatch(userActions.verifyPhoneRequest());

            const register_token = sessionStorage.getItem('register_token')

            await axios.get(`${baseUrl}/user/users/${register_token}/verify_token_phone/${user.token}`)
                .then(res => {
                    dispatch(userActions.verifyPhoneSuccess(res.data));
                    if (res.data.response_code === 'USM013') {
                        toast.error('Token has expired, please resend', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                    } else {
                        toast.success("Success", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                    }

                })
                .catch(error => {
                    dispatch(userActions.verifyPhoneFailure());

                    if (error.response.data.response_code === "USM013") {
                        toast.error('Token has expired, please resend', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                    } else if (error.response.data.response_message === "Phone Verified") {
                        toast.error('Phone has already been verified, please login', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,

                        })
                        // history.push('/')
                    }
                });
        }
    },

    //Get List of security questions
    get_question: () => {
        return async dispatch => {
            dispatch(userActions.getSecurityQuestionsRequest())

            await axios.get(`${baseUrl}/user/security_questions`)
                .then((response) => {
                    const securityObj = response.data.data
                    let securityQuestions = []
                    securityObj.forEach(function (arrayItem) {
                        securityQuestions.push(arrayItem)
                    });
                    dispatch(userActions.getSecurityQuestionsSuccess(securityQuestions))
                });
        }
    },

    //Submit a user's security question
    submit_question: (password, items) => {
        
        const user_id = localStorage.getItem('user_id');

        return async dispatch => {
            dispatch(userActions.submitQuestionRequest())
            await axios.post(`${baseUrl}/user/setsecurity/${user_id}`, { password, items })
                .then((res) => {
                    dispatch(userActions.submitQuestionSuccess(res.data));
                    toast.success("Security questions set successfully. You can now Login", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    });
                }).catch(err => {
                    dispatch(userActions.submitQuestionSuccess());
                    toast.error(err.response.data.data, {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    });
                })
        }
    },
}

export default auth; 