import React, { useEffect, useState } from 'react';
import { Container, Modal, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import auth from '../../../services/authService';
import { GreenButton } from '../../GetrivestButtons';

const SetSecurityQuestionModal = (props) => {
    const [question, setQuestion] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');

    const [answer, setAnswer] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');
    const [answer5, setAnswer5] = useState('');

    const [password, setPassword] = useState('')
    const user_id = props.user.id

    const items = [
        {
            question_id: question,
            answer: answer,
            password: password,
        },
        {
            question_id: question2,
            answer: answer2,
            password: password,
        },
        {
            question_id: question3,
            answer: answer3,
            password: password,
        },
        {
            question_id: question4,
            answer: answer4,
            password: password,
        },
        {
            question_id: question5,
            answer: answer5,
            password: password,
        },
    ];

    function handleChange(event) {
        setQuestion(event.target.value)
    };

    const handleChange2 = (event) => {
        setQuestion2(event.target.value);
    };
    const handleChange3 = (event) => {
        setQuestion3(event.target.value);
    };
    const handleChange4 = (event) => {
        setQuestion4(event.target.value);
    };
    const handleChange5 = (event) => {
        setQuestion5(event.target.value);
    };

    const handleChangeAnswer = (e) => {
        setAnswer(e.target.value);
    };
    const handleChangeAnswer2 = (e) => {
        setAnswer2(e.target.value);
    };
    const handleChangeAnswer3 = (e) => {
        setAnswer3(e.target.value);
    };
    const handleChangeAnswer4 = (e) => {
        setAnswer4(e.target.value);
    };
    const handleChangeAnswer5 = (e) => {
        setAnswer5(e.target.value);
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    };

    function handleSubmit(e) {
        e.preventDefault();
        if (answer && answer2 && answer3 && answer4 && answer5) {
            props.submit_question(password, items, user_id);
        }
    }

    useEffect(() => {
        props.get_question();
    }, []);


    return (
        <Modal show={props.show === true && props.response.success !== true} close={props.close} centered size='lg'>
            <Modal.Body>
                <p>Now set your security questions (5 questions )</p>
                <Form onSubmit={handleSubmit} >
                    <Container>

                        <Row className='mb-3'>
                            <Col lg='6'>

                                <Form.Control as="select" custom
                                    value={question}
                                    name='question'
                                    onChange={handleChange}
                                >
                                    {props.data.map((securityQ, i) => (
                                        <option value={securityQ.id}>{securityQ.question}</option>
                                    ))}


                                </Form.Control>
                            </Col>
                            <Col lg='6'>
                                <Form.Control type='text' placeholder='Answer one'
                                    name='answer'
                                    value={answer}
                                    onChange={handleChangeAnswer}
                                />
                            </Col>
                        </Row>


                        <Row className='mb-3'>
                            <Col lg='6'>
                                <Form.Control as="select" custom
                                    value={question2}
                                    name='question2'
                                    onChange={handleChange2}
                                >
                                    {props.data.map((securityQ, i) => (
                                        <option value={securityQ.id}>{securityQ.question}</option>
                                    ))}


                                </Form.Control>
                            </Col>
                            <Col lg='6'>
                                <Form.Control type='text' placeholder='Answer two'
                                    name='answer2'
                                    value={answer2}
                                    onChange={handleChangeAnswer2}
                                />
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col lg='6'>
                                <Form.Control as="select" custom
                                    value={question3}
                                    name='question3'
                                    onChange={handleChange3}
                                >
                                    {props.data.map((securityQ, i) => (
                                        <option value={securityQ.id}>{securityQ.question}</option>
                                    ))}


                                </Form.Control>
                            </Col>
                            <Col lg='6'>
                                <Form.Control type='text' placeholder='Answer three'
                                    name='answer3'
                                    value={answer3}
                                    onChange={handleChangeAnswer3}
                                />
                            </Col>
                        </Row>


                        <Row className='mb-3'>
                            <Col lg='6'>
                                <Form.Control as="select" custom
                                    value={question4}
                                    name='question4'
                                    onChange={handleChange4}
                                >
                                    {props.data.map((securityQ, i) => (
                                        <option value={securityQ.id}>{securityQ.question}</option>
                                    ))}


                                </Form.Control>
                            </Col>
                            <Col lg='6'>
                                <Form.Control type='text' placeholder='Answer four'
                                    name='answer4'
                                    value={answer4}
                                    onChange={handleChangeAnswer4}
                                />
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col lg='6'>
                                <Form.Control as="select" custom
                                    value={question5}
                                    name='question5'
                                    onChange={handleChange5}
                                >

                                    {props.data.map((securityQ, i) => (
                                        <>
                                            <option value={securityQ.id}>{securityQ.question}</option>
                                        </>

                                    ))}


                                </Form.Control>
                            </Col>
                            <Col lg='6'>
                                <Form.Control type='text' placeholder='Answer five'
                                    name='answer5'
                                    value={answer5}
                                    onChange={handleChangeAnswer5}
                                />
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col lg='6'>
                                <Form.Control type='password' placeholder='Enter your password'
                                    name='password'
                                    value={password}
                                    onChange={handleChangePassword}
                                />
                            </Col>
                            <Col lg='6'>
                                <GreenButton text='SUBMIT' loading={props.loading} />
                            </Col>
                        </Row>

                    </Container>

                </Form>


            </Modal.Body>
        </Modal>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.submitQuestion.isLoading,
        data: state.allSecurityQuestions.data,
        response: state.submitQuestion.data
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_question: () => dispatch(auth.get_question()),
        submit_question: (password, items, user_id) => dispatch(auth.submit_question(password, items, user_id)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetSecurityQuestionModal)