import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import settings from '../../services/settingsService';
import { GreenButton } from '../GetrivestButtons';
import { connect } from 'react-redux';


const Security = (props) => {
    const token = props.user.token;
    const user_id =  props.user.id;
    
    const [change, setChange] = useState({
        old_password: '',
        new_password: '',
        c_new_password: '',
    });

    const [pin, setPin] = useState({
        transaction_pin: '',
        c_transaction_pin: '',
        password: '',
    });

    function onChangePin(e) {
        const { name, value } = e.target
        setPin((pin) => ({ ...pin, [name]: value }));
    };

    function handleSubmitPin(e) {
        e.preventDefault();
        if (pin.transaction_pin && pin.c_transaction_pin && pin.password) {
            props.set_pin(pin, token, user_id).then(() => {
                props.update_user_object(user_id);
            });
        }
    };


    function onChangePassword(e) {
        const { name, value } = e.target
        setChange((change) => ({ ...change, [name]: value }));
    };

    function handleSubmitChange(e) {
        e.preventDefault()
        if (change.old_password && change.new_password && change.c_new_password) {
            props.change_password(change, token, user_id);
        }
    }

    return (
        <div className='ml-lg-4'>
            <h4 className='font-weight-bolder'>Security Details</h4>

            <p className='mt-5'>Set Transacion Pin (4 digits)</p>
            <div className='edit-profile p-3'>

                <Form onSubmit={handleSubmitPin}>
                    <Form.Group>
                        <Form.Control onChange={onChangePin} value={pin.transaction_pin} name='transaction_pin' type="password" placeholder="Enter a 4-digit transaction pin" className='mb-4 mt-4' />
                        <Form.Control onChange={onChangePin} value={pin.c_transaction_pin} name='c_transaction_pin' type="password" placeholder="Confirm Pin" className='mb-4 mt-4' />
                        <Form.Control onChange={onChangePin} value={pin.password} name='password' type="password" placeholder="Enter your password" className='mb-4 mt-4' />
                    </Form.Group>
                    <div className='text-center'>
                        <GreenButton text='Submit' />
                    </div>
                </Form>
            </div>


            <p className='mt-5'>Change Password</p>
            <div className='edit-profile p-3'>

                <Form onSubmit={handleSubmitChange}>
                    <Form.Group>
                        <Form.Control onChange={onChangePassword} value={change.old_password} name='old_password' type="password" placeholder="Old password" className='mb-4 mt-4' />
                        <Form.Control onChange={onChangePassword} value={change.new_password} name='new_password' type="password" placeholder="New password" className='mb-4 mt-4' />
                        <Form.Control onChange={onChangePassword} value={change.c_new_password} name='c_new_password' type="password" placeholder="Confirm new password" className='mb-4 mt-4' />
                    </Form.Group>
                    <div className='text-center'>
                        <GreenButton text='Submit' />
                    </div>
                </Form>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        change_password: (change, token, user_id) => dispatch(settings.change_password(change, token, user_id)),
        set_pin: (pin, token, user_id) => dispatch(settings.set_pin(pin, token, user_id)),
        update_user_object: (id) => dispatch(settings.update_user_object(id))
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Security);
