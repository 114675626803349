import React, { useEffect } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { About } from './pages/About';
import Cart from './pages/Dashboard/Cart';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Dashboard/Settings';
import { Home } from './pages/Home';
import { Packages } from './pages/Packages';
import { history } from './store/history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { connect } from 'react-redux';
import settings from './services/settingsService';
import Faqs from './pages/Faqs';
const CrossStorage = require('cross-storage-plus');

function App(props) {
  let id;
  let store;

  async function storage() {
    store = await CrossStorage.initializeClient('https://getripay.lending.ng/index.html');
    const user_id = await store.getItem('user_id')
    id = user_id;
    if (id) {
      props.update_user_object(id)
    }
  }

  useEffect(() => {
    storage()
  }, []);

  return (
    <div>
      <Router history={history} >
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/packages" component={Packages} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/cart" component={Cart} />
          <Route path="/settings" component={Settings} />
          <Route path="/faqs" component={Faqs} />
        </Switch>
      </Router>

      <ToastContainer />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    update_user_object: (id) => dispatch(settings.update_user_object(id))
  };
};



export default connect(null, mapDispatchToProps)(App);
