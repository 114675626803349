import React, {useState} from 'react'
import { history } from '../../store/history';
import { OrangeDashboardButton, GreenDashboardButton, PlainOrangeDashboardButton } from '../GetrivestButtons'
import { numberFormatter } from '../numberFormatter';
import  FundAccount  from './FundAccount';


export const ProfileCard = (props) => {
    const [show, setShow] = useState(false);
    const photo = props.user.profile_photo;
    const url = 'http://api.test.getripay.com';  //test
    //const url = 'https://api.getripay.com';  //live
    const completeUrl = url + photo;

    const handleChange = (event) => {
        setShow((prev) => !prev);
    };


    return (
        <>
            <div className='profile-card text-center'>
                <img src={completeUrl} className='card-img mt-4' alt='profile' />
                <h6 className='mt-2 font-weight-bold'>
                    {props.user.first_name} {props.user.last_name}
                </h6>
                <div className='account-round mb-3 text-left'>
                    <img src='/images/yellow-logo.svg' alt='logo' />
                    <span className='text-right ml-4'>
                        {props.user.bank_account ?
                            `${props.user.bank_account[0].account_number}`
                            :
                            ''

                        }
                    </span>
                </div>
                <p className='card-small dash-small m-auto'>AVAILABLE BALANCE</p>
                <p className='dashboard-balance mt-2'>
                    {props.balance.length !== 0 ?
                        `NGN ${numberFormatter(props.balance.available_balance)}`
                        :
                        ' NGN 0'
                    }
                </p>
                <OrangeDashboardButton text='Fund Account' click={handleChange}/>
                {show &&
                    <FundAccount />
                }
                <div className='mt-3 mb-5'>
                    <GreenDashboardButton click={()=>history.push('/settings')} text=' Account Settings' />
                </div>
            </div>

            <div className='account-actions mt-5 p-3'>
                <p className='font-weight-bolder'>ACCOUNT ACTIONS</p>
                <img src='/images/checkmark.png' alt='check' /> Confirm email
                <div className='mt-3'>
                    <img src='/images/checkmark.png' alt='check' /> Update profile picture
                </div>
                <div className='mt-3'>
                    <img src='/images/checkmark.png' alt='check' /> Verify BVN
               </div>

                <div className='mt-4'>
                    <PlainOrangeDashboardButton text='Edit Profile' click={()=>history.push('/settings')} />
                </div>

            </div>
        </>

    )
}
