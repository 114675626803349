import React from 'react';
import { Accordion, Card, Container, Row, Col } from 'react-bootstrap';

export const FaqAccordion = () => {
    return (
        <Container className='mt-5 mb-5'>
            <Row>
                <Col lg={2}></Col>
                <Col lg={8}>
                    <p className='text-center font-weight-bolder'>Frequently Asked Questions</p>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                WHAT IS GETRIVEST?
                        </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    We are a technology-driven agro-investment platform created for the purpose
                                    of enabling both farmers and investors achieve their individual dreams while
                                    solving a larger problem collectively. Farmers and investors get to pull resources together to
                                    solve the problem of funding for farmers and at the same time give investors a viable source
                                    of income and return on investment.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                WHO CAN INVEST?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    Anyone can invest. GetriVest welcomes investment from professionals, students,
                                    business owners or any individual who wants an opportunity to grow their finance without
                                    having to be physically present to monitor the growth of the business. It is also a good place to
                                    start if you are an agricultural enthusiast who wants to see farmers do well and help boost
                                    agricultural production in Africa.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                HOW MUCH DO I HAVE TO INVEST?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    How much you can invest would depend on the package you have chosen to join.
                                    The amount for each package is stated under each category.

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                FOR HOW LONG DO I HAVE TO INVEST?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    This would also be determined by the type of package you have chosen.
                                    Duration of investment is clearly stated under each package category.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>



                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                HOW DO I GET PAID?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    ROI (Return On Investment) will be paid directly into your personal GetriPay account.

                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                                I HAVE OTHER QUESTIONS THAT ARE NOT LISTED HERE.?
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    If you have further enquiries, kindly send us a mail at hello@getrivest.com or call us on any of our phone lines listed below:
                                  <br/>  07012345678<br/> 
                                    08012345678


                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
                <Col lg={2}></Col>
            </Row>

        </Container>
    )
}
