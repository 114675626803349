import React from 'react'

export const InformationBox = () => {
    return (
        <div className='information-box pt-4 pb-4 pl-3 pr-3'>
            <div className='information-box-inner p-2'>
                <img src='images/notice_icon.png' className='img-fluid' width='30' alt='info' />
                <p className='mt-3'>
                    COVID-19 is real.
                    Wash your hands regularly, mask up, sanitize and observe social distance.
                </p>
            </div>
        </div>
    )
}
