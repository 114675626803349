import React from 'react';

export const DashboardBanner = (props) => {
    console.log(props)
    return (
        <div className='dashboard-banner' style={{ backgroundImage: `url(${props.image})` }}>
            <h1 className='text-center  dashboard-banner-title'>{props.text}</h1>
            <div className='text-center'>
                <span className='dashboard-banner-space'>Howdy!</span>
                {props.user &&
                    <span className='getrivest-green dashboard-banner-space font-weight-bold'>{props.user.first_name} {props.user.last_name}</span>
                }

            </div>

        </div>

    )
}
