import React, { useState } from 'react'
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import { AccountBox } from './AccountBox';
import { InvestmentBox } from './InvestmentBox';
import { PaymentsBox } from './PaymentsBox';
import { ReturnsBox } from './ReturnsBox';
import { StartNewBox } from './StartNewBox';
import { UnitsInvestedBox } from './UnitsInvestedBox';

export const TabComponent = (props) => {
  const [key, setKey] = useState('summary');

  return (
    <>
      <Tabs
        className='nav-justified'
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        style={{ border: '2px solid #999999' }}
      >
        <Tab eventKey="summary" title="Account Summary">
          <h5 className='mt-5 font-weight-bolder ml-4 '>Account Summary</h5>
          <Container>
            <Row>
              <Col lg='6' md='12' sm='12' className='mt-4'>
                <ReturnsBox {...props} />
              </Col>
              <Col lg='6' md='12' sm='12' className='mt-4'>
                <AccountBox {...props}/>
              </Col>
              {/* <Col lg='6' md='12' sm='12' className='mt-5'>
                <UnitsInvestedBox />
              </Col>
              <Col lg='6' md='12' sm='12' className='mt-5'>
                <PaymentsBox />
              </Col> */}
            </Row>
          </Container>
        </Tab>
        <Tab eventKey="Investments" title="Investments">
          <h5 className='mt-5 font-weight-bolder'>Investments</h5>
          <Container>
            <Row>
              <Col lg='6' md='12' sm='12' className='mt-4'>
                <StartNewBox text='Add a new investment' />
              </Col>
              {props.sub && Object.entries(props.sub).length !== 0 ?
                <>
                  {props.sub.map((newData, i) => (
                    <Col lg='6' md='12' sm='12' className='mt-4' key={i} >
                      <InvestmentBox {...newData} />
                    </Col>
                  ))}
                </>
                :
               <p>You don not have an active subscription</p>
              }


            </Row>
          </Container>
        </Tab>
        <Tab eventKey="Payments" title="Payments" >
          <h5 className='mt-5 font-weight-bolder'>Payments</h5>
          <Container>
            <Row>
              <Col lg='6' md='12' sm='12' className='mt-4'>
                <StartNewBox text='Request a new payment' />
              </Col>

            </Row>
          </Container>
        </Tab>
      </Tabs>
    </>
  )
}
