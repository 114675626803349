import { userConstants } from "./types";

export const userActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  verifyPhoneRequest,
  verifyPhoneSuccess,
  verifyPhoneFailure,
  getSecurityQuestionsRequest,
  getSecurityQuestionsSuccess,
  submitQuestionRequest,
  submitQuestionSuccess,

  listAllProductsRequest,
  listAllProductsSuccess,
  verifyLoginRequest,
  verifyLoginSuccess,
  verifyLoginFailure,
  viewProductDetailRequest,
  viewProductDetailSuccess,
  subscriptionRequest,
  subscriptionSuccess,
  subscriptionFailure,
  listSubscriptionRequest,
  listSubscriptionSuccess,
  investmentHistoryRequest,
  investmentHistorySuccess,
  certificateRequest,
  certificateSuccess,

  updateProfileRequest,
  updateProfileSuccess,
  updateProfileFailure,
  updateProfilePictureRequest,
  updateProfilePictureSuccess,
  updateProfilePictureFailure,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  setPinRequest,
  setPinSuccess,
  setPinFailure,
  updateUserObject,
  accountBalanceRequest,
  accountBalanceSuccess,
  submitBvnRequest,
  submitBvnSuccess,
  submitBvnFailure,
  submitBvnFailure,
  verifyBvnRequest,
  verifyBvnSuccess,
  uploadDocumentRequest,
  uploadDocumentSuccess
}


// LOGIN
function loginRequest() {
  return {
    type: userConstants.LOGIN_REQUEST,
  };
};

function loginSuccess(payload) {
  return {
    type: userConstants.LOGIN_SUCCESS,
    payload,
  };
};

function loginFailure() {
  return {
    type: userConstants.LOGIN_FAILURE,
  };
};

// VERIFY LOGIN REQUEST
function verifyLoginRequest() {
  return {
    type: userConstants.VERIFY_LOGIN_REQUEST,
  };
};

function verifyLoginSuccess(payload) {
  return {
    type: userConstants.VERIFY_LOGIN_SUCCESS,
    payload,
  };
};

function verifyLoginFailure(payload) {
  return {
    type: userConstants.VERIFY_LOGIN_FAILURE,
    payload
  };
};


// REGISTER
function registerRequest() {
  return {
    type: userConstants.REGISTER_REQUEST,
  };
};

function registerSuccess(payload) {
  return {
    type: userConstants.REGISTER_SUCCESS,
    payload,
  };
};

function registerFailure() {
  return {
    type: userConstants.REGISTER_FAILURE,
  };
};

// VERIFY A USER'S PHONE NUMBER
function verifyPhoneRequest() {
  return {
    type: userConstants.VERIFY_PHONE_REQUEST,
  };
};

function verifyPhoneSuccess(payload) {
  return {
    type: userConstants.VERIFY_PHONE_SUCCESS,
    payload,
  };
};

function verifyPhoneFailure() {
  return {
    type: userConstants.VERIFY_PHONE_FAILURE,
  };
};

// FETCH SECURITY QUESTIONS
function getSecurityQuestionsRequest() {
  return {
    type: userConstants.ALL_SECURITY_QUESTIONS_REQUEST,
  };
};

function getSecurityQuestionsSuccess(payload) {
  return {
    type: userConstants.ALL_SECURITY_QUESTIONS_SUCCESS,
    payload,
  };
};

// SUBMIT SECURITY QUESTIONS
function submitQuestionRequest() {
  return {
    type: userConstants.SUBMIT_QUESTION_REQUEST,
  };
};

function submitQuestionSuccess(payload) {
  return {
    type: userConstants.SUBMIT_QUESTION_SUCCESS,
    payload,
  };
};
















function listAllProductsRequest() {
  return {
    type: userConstants.LIST_ALL_PRODUCTS_REQUEST,
  };
}

function listAllProductsSuccess(payload) {
  return {
    type: userConstants.LIST_ALL_PRODUCTS_SUCCESS,
    payload,
  };
}

function viewProductDetailRequest() {
  return {
    type: userConstants.VIEW_PRODUCT_DETAIL_REQUEST,
  };
}

function viewProductDetailSuccess(payload) {
  return {
    type: userConstants.VIEW_PRODUCT_DETAIL_SUCCESS,
    payload,
  };
}


// SUBSCRIBE TO INVESTMENT
function subscriptionRequest() {
  return {
    type: userConstants.SUBSCRIPTION_REQUEST,
  };
};

function subscriptionSuccess(payload) {
  return {
    type: userConstants.SUBSCRIPTION_SUCCESS,
    payload,
  };
};

function subscriptionFailure() {
  return {
    type: userConstants.SUBSCRIPTION_FAILURE,
  };
};

// GET A USER'S INVESTMENT SUBSCRIPTION
function listSubscriptionRequest() {
  return {
    type: userConstants.LIST_SUBSCRIPTION_REQUEST,
  };
};

function listSubscriptionSuccess(payload) {
  return {
    type: userConstants.LIST_SUBSCRIPTION_SUCCESS,
    payload,
  };
};


// GET A USER'S INVESTMENT HISTORY
function investmentHistoryRequest() {
  return {
    type: userConstants.INVESTMENT_HISTORY_REQUEST,
  };
};

function investmentHistorySuccess(payload) {
  return {
    type: userConstants.INVESTMENT_HISTORY_SUCCESS,
    payload,
  };
};



// DOWNLOAD AN INVESTMENT CERTIFICATE
function certificateRequest() {
  return {
    type: userConstants.CERTIFICATE_REQUEST,
  };
};

function certificateSuccess(payload) {
  return {
    type: userConstants.CERTIFICATE_SUCCESS,
    payload,
  };
};




// ACCOUNT SETTINGS


// UPDATE  A USER'S PROFILE
function updateProfileRequest() {
  return {
    type: userConstants.UPDATE_PROFILE_REQUEST,
  };
};

function updateProfileSuccess(payload) {
  return {
    type: userConstants.UPDATE_PROFILE_SUCCESS,
    payload,
  };
};

function updateProfileFailure() {
  return {
    type: userConstants.UPDATE_PROFILE_FAILURE,
  };
};


// UPDATE A USER'S PROFILE PICTURE
function updateProfilePictureRequest() {
  return {
    type: userConstants.UPDATE_PROFILE_PICTURE_REQUEST,
  };
};

function updateProfilePictureSuccess(payload) {
  return {
    type: userConstants.UPDATE_PROFILE_PICTURE_SUCCESS,
    payload,
  };
};

function updateProfilePictureFailure() {
  return {
    type: userConstants.UPDATE_PROFILE_PICTURE_FAILURE,
  };
};


// CHANGE A USER'S PASSSWORD
function changePasswordRequest() {
  return {
    type: userConstants.CHANGE_PASSWORD_REQUEST,
  };
};

function changePasswordSuccess(payload) {
  return {
    type: userConstants.CHANGE_PASSWORD_SUCCESS,
    payload,
  };
};

function changePasswordFailure() {
  return {
    type: userConstants.CHANGE_PASSWORD_FAILURE,
  };
};


// SET A USER'S TRANSACTION PIN
function setPinRequest() {
  return {
    type: userConstants.SET_PIN_REQUEST,
  };
};

function setPinSuccess(payload) {
  return {
    type: userConstants.SET_PIN_SUCCESS,
    payload,
  };
};

function setPinFailure() {
  return {
    type: userConstants.SET_PIN_FAILURE,
  };
};



// UPDATE USER OBJECT
function updateUserObject(payload) {
  return {
    type: userConstants.UPDATE_USER_OBJECT,
    payload,
  };
}


// GET A USER'S ACCOUNT BALANCE
function accountBalanceRequest() {
  return {
    type: userConstants.ACCOUNT_BALANCE_REQUEST,
  };
};

function accountBalanceSuccess(payload) {
  return {
    type: userConstants.ACCOUNT_BALANCE_SUCCESS,
    payload,
  };
};



// SUBMIT A USER'S BVN FOR VALIDATION
function submitBvnRequest() {
  return {
    type: userConstants.SUBMIT_BVN_REQUEST,
  };
};

function submitBvnSuccess(payload) {
  return {
    type: userConstants.SUBMIT_BVN_SUCCESS,
    payload,
  };
};

function submitBvnFailure() {
  return {
    type: userConstants.SUBMIT_BVN_FAILURE,
  };
};

// VERIFY A USER'S BVN
function verifyBvnRequest() {
  return {
    type: userConstants.SUBMIT_BVN_REQUEST,
  };
};

function verifyBvnSuccess(payload) {
  return {
    type: userConstants.SUBMIT_BVN_SUCCESS,
    payload,
  };
};



// UPLOAD A VERIFICATION DOCUMENT
function uploadDocumentRequest() {
  return {
    type: userConstants.UPLOAD_DOCUMENT_REQUEST,
  };
};

function uploadDocumentSuccess(payload) {
  return {
    type: userConstants.UPLOAD_DOCUMENT_SUCCESS,
    payload,
  };
};