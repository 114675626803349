import React from 'react';
import { Container, Modal, Row, Col, Form } from 'react-bootstrap';
import { GreenDashboardButton } from '../GetrivestButtons';
import { Loader } from '../Loader';

export const PinModal = (props) => {

    return (
        <>
            <Modal show={props.show} onHide={props.close} size='md' centered>
                {props.loading || props.loadingNew ?
                    <Loader />
                    :
                    ''
                }
                <Modal.Body className='p-0'>
                    <Container fluid className='m-0 p-0'>
                        <Row>

                            <Col lg='12' md='12' sm='12' xs='12' className='text-center pt-3 pb-5 mt-5 pl-lg-5 pr-lg-5'>
                                <h5 className='registration-title mb-4'>Enter your GetriPay transaction pin</h5>

                                <Form>
                                    <Form.Group>
                                        <Form.Control type="password" placeholder="Transaction Pin" className='mb-4'
                                            onChange={props.change} value={props.value} name={props.name}
                                        />
                                    </Form.Group>
                                </Form>
                                <GreenDashboardButton text='SUBMIT'click={props.submit}/>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}