import React from 'react'
import { DashboardBanner } from '../components/Dashboard/DashboardBanner'
import Navigation from '../components/Navigation'
import { FaqAccordion } from '../components/Faqs/FaqAccordion'
import { Footer } from '../components/Footer'

export const Faqs = (props) => {
    return (
        <div >
            <Navigation/>
            <DashboardBanner text='FAQS' image='/images/header_bg.png' />
            <FaqAccordion/>
            <Footer/>
        </div>
    )
}

export default Faqs;