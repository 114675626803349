import React from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';
import { numberFormatter } from '../numberFormatter';

export const InvestmentDetailModal = (props) => {

    const start = new Date(props.start_date)
    const end = new Date(props.end_date)

    // To calculate the time difference of two dates 
    var Difference_In_Time = end.getTime() - start.getTime();

    // To calculate the no. of days between two dates 
    var investmentPeriod = Math.floor(Difference_In_Time / (1000 * 3600 * 24));

    const begun = props.diff - investmentPeriod

    return (
        <>
            <Modal show={props.show} onHide={props.close} size='lg'>
                <Modal.Header closeButton className='pl-5 pr-5'>
                    <img src='/images/chickens.jpg' alt='chicken' style={{ marginTop: '-16px', width: '100%' }} />
                </Modal.Header>
                <Modal.Body className='ml-3'>
                    {begun <= 0 ?
                        <h5 className='blinking text-center font-weight-bolder'>Cycle is ongoing</h5>
                        :
                        <h5 className='blinking text-center font-weight-bolder'>{begun} days to go (for cycle to begin)</h5>
                    }
                                    <Container>
                        <Row>

                            <Col lg={6} md={6} sm={12} xs={12} className='mt-3'>

                                <span className='package-modal-title'>{props.name}</span>
                                <div>

                                    <span className='card-small'> ROI - </span>
                                    <span className='package-modal-roi'>{props.interest_rate}%</span>
                                </div>
                                <span className='package-modal-unit'>NGN {numberFormatter(props.amount / props.slots)}/Unit</span>
                                <span className='package-modal-date'>{new Date(props.start_date).toDateString()} - {new Date(props.end_date).toDateString()}</span>
                                <span className='package-modal-period font-weight-bold'> ({investmentPeriod} days)</span>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12} className='mt-3'>
                                <p className='getrivest-green m-1' style={{ textTransform: 'uppercase' }}>{props.status} INVESTMENT </p>
                                <h5>{props.slots} Units</h5>
                                <Row>
                                    <Col lg='2' sm='3' xs='3'>
                                        <p className='modal-roi card-small p-1 m-0 text-center'>ROI</p>
                                    </Col>
                                    <Col lg='3' sm='3' xs='3' >
                                        <p className='font-weight-bolder'> {props.interest_rate}% </p>
                                    </Col>
                                    <Col lg='1' sm='1' xs='1'>
                                        <p className='font-weight-bolder'> | </p>
                                    </Col>
                                    <Col lg='3' sm='3' xs='3' >
                                        <p className='font-weight-bolder getrivest-green text-left'>NGN{numberFormatter(props.amount)} </p>
                                    </Col>
                                    <Col lg='4'>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg='4' sm='6' xs='6'>
                                        <p className='box-left card-small p-1 m-0'>DAYS LEFT</p>
                                    </Col>
                                    <Col lg='5' sm='6' xs='6'>
                                        <p className='font-weight-bolder'>{props.diff}days</p>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <div className='investment-modal-footer p-3 text-center'>
                    <a target="_blank" rel="noopener noreferrer"  href={`http://api.test.getripay.com/api/v1/savinvest/download_investment_certificate/${props.id}`} className='text-center text-white font-weight-bolder'>DOWNLOAD CERTIFICATE</a>
                </div>
            </Modal>
        </>
    )
}
