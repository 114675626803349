import axios from 'axios';
import config from '../config';
import { userActions } from "../actions/creators.js";
import { toast, Flip } from 'react-toastify';
import { history } from '../store/history';

const baseUrl = `${config.apiUrl}`;

const account = {
    // To get Account balance
    get_account_balance: (account_no) => {
        return async dispatch => {
            dispatch(userActions.accountBalanceRequest());

            await axios.get(`${baseUrl}/accounts/${account_no}/balance`)
                .then((res) => {
                    dispatch(userActions.accountBalanceSuccess(res.data.data));
                });
        }
    },

    submit_bvn: (validate, token) => {
        return async dispatch => {
            dispatch(userActions.submitBvnRequest());
            await axios.post(`${baseUrl}/user/validate-bvn`, validate, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then((res) => {
                if (res.data.response_code === 'USM032') {
                    toast.success("Your BVN has already been validated", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                    dispatch(userActions.submitBvnFailure());
                } else if (res.data.response_code === 'USM023') {
                    dispatch(userActions.submitBvnSuccess(res.data));
                    toast.success("Success. Please enter yout date of birth", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                } else {
                    dispatch(userActions.submitBvnSuccess(res.data));
                }

            }).catch((err) => {
                dispatch(userActions.submitBvnFailure());
                toast.error("BVN validation failed. Please try again later", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            });
        }
    },

    verify_bvn: (verify, token) => {
        return async dispatch => {
            dispatch(userActions.verifyBvnRequest());
            await axios.post(`${baseUrl}/user/verify-bvn-date-of-birth`, verify, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then((res) => {
                const message = res.data.response_message;
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                });

                dispatch(userActions.verifyBvnSuccess(res.data.data));
                // history.push('/dashboard')
            }).catch((err) => {
                if (err.response.data.response_code === 'USM029') {
                    toast.error("Incorrect date of birth", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                } else {
                    toast.error("An error occured", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                }

            });
        }
    },

    upload_document: (document, images, token) => {
        return async dispatch => {
            const formData = new FormData();
            formData.append('images', images);
            formData.append('user_id', document.user_id);
            formData.append('kyc_level_name', document.kyc_level_name);
            formData.append('kyc_level_id', document.kyc_level_id);
            formData.append('name', document.name);
            formData.append('identification_number', document.identification_number);
            formData.append('type', document.type);

            dispatch(userActions.uploadDocumentRequest());
            await axios.post(`${baseUrl}/user/user_documents`, formData, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                dispatch(userActions.uploadDocumentSuccess(res.data.success));

                toast.success('Document Upload Successful', {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            }).catch(error => {
                toast.error(error.response.data.response_message, {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            });
        };
    },


}

export default account;