import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { GreenButton } from '../GetrivestButtons';
import { connect } from 'react-redux';
import account from '../../services/accountService';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

const UploadDocument = (props) => {
    const token = props.user ? props.user.token : '';

    const [document, setDocument] = useState({
        user_id: props.user.id,
        kyc_level_name: "Getripay Gold",
        kyc_level_id: "3",
        name: '',
        identification_number: '',
        type: 'document'
    });


    function handleChange(e) {
        const { name, value } = e.target;
        setDocument((document) => ({ ...document, [name]: value }));
    }

    const handleChangePicture = ({ meta, file }, status) => { console.log(status, meta, file) }

    const handleSubmitPicture = ( files, allFiles) => {
        if (document.name && document.identification_number && files[0].file) {
            props.upload_document(document, files[0].file, token)
        }else{
            alert('Please fill all fields')
        }
    }

    return (
        <div className='ml-lg-4'>
            <h5 className='font-weight-bolder'>Upload Identification document</h5>

            <div className='edit-profile mt-4 p-3'>
                    <div onChange={handleChange}>
                        <p>Select a document type to upload</p>
                        <Form.Check type="radio" name='name' value='National ID Card' aria-label="National ID Card" label="National ID Card" />
                        <Form.Check type="radio" name='name' value="Driver's License" aria-label="Driver's License" label="Driver's License" />
                        <Form.Check type="radio" name='name' value="Voter's Card" aria-label="Voter's Card" label="Voter's Card" />
                        <Form.Check type="radio" name='name' value='International Passport' aria-label="International Passport" label="International Passport" />
                    </div>

                    <Form.Group>
                        <Form.Control onChange={handleChange} name='identification_number' value={document.identification_number} type="text" placeholder="Document identification number" className='mb-4 mt-4' />
                    </Form.Group>

                    <Dropzone
                        maxFiles={1}
                        multiple={false}
                        onChangeStatus={handleChangePicture}
                        onSubmit={handleSubmitPicture}
                        inputContent={<p>Click here to choose an image to upload</p>}
                        accept="image/*"
                    />
            </div>
        </div >
    )
};


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.uploadDocument.isLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        upload_document: (document, images, token) => dispatch(account.upload_document(document, images, token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocument);