import React, { useState } from 'react';
import { Container, Modal, Row, Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import auth from '../../../services/authService';
import { GreenDashboardButton } from '../../GetrivestButtons';
import { Loader } from '../../Loader';

const LoginModal = (props) => {
    const [user, setUser] = useState({
        email: '',
        password: '',
        channel: 'web',
    });
    const [answer, setAnswer] = useState('');
    const answer_id = props.newQuestion !==undefined ? props.newQuestion.answer_id : props.data.data ? props.data.data.answer_id : ''
    function handleChange(e) {
        const { name, value } = e.target;
        setUser(user => ({ ...user, [name]: value }));
    }

    function handleChangeAnswer(e) {
        setAnswer(e.target.value)
    }

    function submit() {
        if (user) {
            props.login_user(user)
            user.password = ''
        }
    };

    function finalLogin() {
        if (answer) {
            props.verify_login(answer, answer_id)
        }
    };

    return (
        <>
            <Modal show={props.show} onHide={props.close} size='lg' centered>
                {props.loading || props.loadingNew ?
                    <Loader />
                    :
                    ''
                }
                <Modal.Body className='p-0'>
                    <Container fluid className='m-0 p-0'>
                        <Row>
                            <Col lg='6' md='12' sm='12' xs='12'>
                                <img src='/images/pattern_bg.png' alt='pattern' className='img-fluid reg-image' />
                            </Col>
                            {props.data.length !== 0 && answer_id ?
                                <Col lg='6' md='12' sm='12' xs='12' className='pl-4 pr-5 pt-3 pb-5 mt-5'>
                                    <h5 className='registration-title mb-4'>Answer your security question</h5>

                                    <Form>
                                        {props.newQuestion !== undefined
                                            ?
                                            <div>
                                                <p className='font-weight-bold'>{props.newQuestion.question}</p>
                                            </div>
                                            :
                                            <p className='font-weight-bold' >{props.data.data.question}</p>
                                        }
                                        <Form.Group>
                                            <Form.Control type="password" placeholder="Answer to security question" className='mb-4'
                                                onChange={handleChangeAnswer} value={answer} name='answer'
                                            />
                                        </Form.Group>
                                    </Form>
                                    <div className='text-center'>
                                        <GreenDashboardButton text='Continue' click={finalLogin} loading={props.loadingNew} />
                                        <p className='mt-5 text-center card-small'>Do not have a getripay account? Register</p>
                                    </div>
                                </Col>
                                :
                                <Col lg='6' md='12' sm='12' xs='12' className='pl-4 pr-5 pt-3 pb-5 mt-5'>
                                    <h6 className='registration-title mb-4'>Login with your GetriPay account</h6>

                                    <Form>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Username/e-mail/Phone number" className='mb-4'
                                                onChange={handleChange} value={user.email} name='email'
                                            />
                                            <Form.Control type="password" placeholder="Password" className='mb-4'
                                                onChange={handleChange} value={user.password} name='password'
                                            />
                                        </Form.Group>
                                    </Form>
                                    <div className='text-center'>
                                        <GreenDashboardButton text='Continue' click={submit} loading={props.loading} />
                                        <p onClick={props.openNew} className='mt-5 text-center card-small login-link'>Do not have a getripay account? Register</p>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.login.isLoading,
        data: state.login.data,
        loadingNew: state.userDetails.isLoading,
        newQuestion: state.userDetails.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login_user: (user) => dispatch(auth.login_user(user)),
        verify_login: (answer, answer_id) => dispatch(auth.verify_login(answer, answer_id))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)