import React from 'react';
import { Spinner } from 'react-bootstrap';

export const OrangeButton = (props) => {
    return (
        <div>
            <button className='orange-button'>{props.text}</button>
        </div>
    )
}

export const OrangeButtonLong = (props) => {
    return (
        <div>
            <button disabled={props.disable}
                className={props.disable ? 'orange-button-disable' : 'orange-button-long'}
                onClick={props.click}>{props.text}</button>
        </div>
    )
}


export const GreenButton = (props) => {
    return (
        <div disabled={props.disable}
        >
            <button type='submit' className={props.disable ? 'orange-button-disable' : 'green-button'} onClick={props.click}>
                {props.loading && <Spinner animation="border" variant="light" />}
                {props.text}
            </button>
        </div>
    )
}

export const LearnButton = (props) => {
    return (
        <div>
            <a href='#learn'>
                <button className='green-button'>
                    {props.text}
                </button>
            </a>

        </div>
    )
}

export const SmallGreenButton = (props) => {
    return (
        <div >
            <button type='submit' className='small-green-button' onClick={props.click}>
                {props.loading && <Spinner animation="border" variant="light" />}
                {props.text}
            </button>
        </div>
    )
}

export const PlainGreenButton = (props) => {
    return (
        <div>
            <button className='plain-green-button'>{props.text}</button>
        </div>
    )
}

export const WorksButton = (props) => {
    return (
        <div>
            <a href='#works'>
                <button className='plain-green-button'>
                    {props.text}
                </button>
            </a>

        </div>
    )
}

export const OrangeDashboardButton = (props) => {
    return (
        <div>
            <button onClick={props.click} className='orange-dashboard-button font-weight-bold'>{props.text}</button>
        </div>
    )
}

export const PlainOrangeDashboardButton = (props) => {
    return (
        <div>
            <button onClick={props.click} className='plain-orange-dashboard-button font-weight-bold'>{props.text}</button>
        </div>
    )
}
export const GreenDashboardButton = (props) => {
    return (
        <div>
            <button className='green-dashboard-button font-weight-bold' type='submit' onClick={props.click}>
                {props.loading && <Spinner animation="border" variant="light" />}
                {props.text}
            </button>
        </div>
    )
}