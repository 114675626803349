import React from 'react'
import { AboutBanner } from '../components/About/AboutBanner'
import { AboutSectionThree } from '../components/About/AboutSectionThree'
import { AboutSectionTwo } from '../components/About/AboutSectionTwo'
import { Footer } from '../components/Footer'
import Navigation from '../components/Navigation'

export const About = () => {
    return (
        <div>
            <Navigation/>
            <AboutBanner/>
            <AboutSectionTwo/>
            <AboutSectionThree/>
            <Footer/>
        </div>
    )
}
