import React, {useEffect} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import investment from '../../services/investmentService'
import { history } from '../../store/history'
import CardComponent  from '../CardComponent'
import { GreenButton } from '../GetrivestButtons'

const SectionTwo = (props) => {

    useEffect(() => {
        props.get_all_product();
    }, []);
    return (
        <div className='section-two2 mb-5'>
            <p className='text-center ques'>Ready to invest?</p>
            <h2 className='text-center section-two-text'>Start Your Farm Today</h2>


            <Container className='mt-5'>
                <Row>
                {props.data && props.data.map((newData, i) => (
                        <Col md={6} lg={4} sm={12} xs={12} key={i}>
                            <CardComponent {...newData} />
                        </Col>
                    ))}

                   <div className='m-auto'>
                       <GreenButton text='View all Packages' click={()=>history.push('/packages')}/>
                   </div>
                </Row>
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        loading: state.listAllProducts.isLoading,
        data:state.listAllProducts.data

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_all_product: () => dispatch(investment.get_all_product()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionTwo);
