import React from 'react'
import { Banner } from '../components/Home/Banner';
import Navigation from '../components/Navigation';
import SectionTwo from '../components/Home/SectionTwo';
import { SectionThree } from '../components/Home/SectionThree';
// import { Testimonials } from '../components/Home/Testimonials';
import { Footer } from '../components/Footer';

export const Home = () => {
    return (
        <div>
            <Navigation />
            <Banner/>
            <SectionTwo/>
            <SectionThree/>
            {/* <Testimonials/> */}
            <Footer/>
        </div>
    )
}
