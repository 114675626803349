import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { GreenButton } from '../GetrivestButtons'
import { numberFormatter } from '../numberFormatter'

export const AccountBox = (props) => {
    return (
        <div className='box p-3'>
            <p className='getrivest-orange mb-3'>Account</p>

            <p className='account-grey card-small p-1 m-0'>BALANCE</p>
            <h5 className='box-roi-value'>
                {props.balance.length !== 0 ?
                    `NGN ${numberFormatter(props.balance.available_balance)}`
                    :
                    ' NGN 0'
                }
            </h5>

            <p className='account-grey2 card-small p-1 m-0'>LEDGER BALANCE</p>
            <h6 >
                {props.balance.length !== 0 ?
                    `NGN ${numberFormatter(props.balance.book_balance)}`
                    :
                    ' NGN 0'
                }
            </h6>

            <Row className='align-items-end mt-3'>
                <Col lg='5' sm='6' xs='6'>
                    <GreenButton text='Fund Account' />
                </Col>
                <Col lg='7' sm='6' xs='6' className='text-right'>
                    <p className='card-small p-1 m-0 box-info-col ml-lg-5'>ACCOUNT INFO</p>
                    <p className='getrivest-orange m-0 font-weight-bold box-num'>
                        {props.user.bank_account ?
                            `NGN ${props.user.bank_account[0].account_number}`
                            :
                            ''

                        }
                    </p>
                    <p className='getrivest-green m-0 font-weight-bold box-name'>{props.user.first_name} {props.user.last_name}</p>
                </Col>
            </Row>


        </div>
    )
}
